import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { DelayInput } from 'saas/page/product/tryon/SettingsCard/DelayInput'
import { EmailCheckbox } from 'saas/page/product/tryon/SettingsCard/EmailCheckbox'
import { LanguageSelect } from 'saas/page/product/tryon/SettingsCard/LanguageSelect'
import { TimeoutInput } from 'saas/page/product/tryon/SettingsCard/TimeoutInput'

export const GeneralTab = observer(() => {
  return <Vertical>
    <Typo size={14} bold>{i18n('label.Language')}</Typo>
    <Gap height={8} />
    <LanguageSelect />
    <Gap height={20} />
    <Typo size={14} bold>{i18n('widget.EmailsCollection')}</Typo>
    <Gap height={12} />
    <EmailCheckbox />
    <Gap height={16} />
    <DelayInput />
    <Gap height={16} />
    <TimeoutInput />
  </Vertical>
})
