import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Condition } from 'saas/page/group/components/Condition'
import { Description } from 'saas/page/group/components/Description'
import { FilterRow } from 'saas/page/group/components/FilterRow/FilterRow'
import { Name } from 'saas/page/group/components/Name'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

export const MainView = observer(() => {
  const { filters, canReset, canSave } = groupFormSaas.it

  const onAddCondition = useLatestCallback(() => {
    groupFormSaas.it.filters.data.add(new GroupFilterStore())
  })

  const onReset = useLatestCallback(() => {
    groupFormSaas.it.reset()
  })

  const onSave = useLatestCallback(async () => {
    await groupFormSaas.it.save()
    groupPreviewSaas.it.refresh()
  })

  return <Vertical gap={24}>
    <Card pad={24} vertical>
      <Vertical gap={16} width={400}>
        <Name />
        <Description />
      </Vertical>
    </Card>
    <Card pad={24} vertical>
      <Typo size={16} semi>{i18n('group.Conditions')}</Typo>
      <Gap height={16} />
      <Condition />
      <Gap height={24} />
      <Vertical gap={12}>
        {filters.value.map(filter => <FilterRow key={filter.key} filter={filter} />)}
        {filters.empty && <Space height={32}>
          <Typo size={14} tertiary error={filters.error}>
            {i18n('group.NoConditions')}
          </Typo>
        </Space>}
      </Vertical>
      <Gap height={16} />
      <Space>
        <Button onClick={onAddCondition}>{i18n('group.AddCondition')}</Button>
      </Space>
    </Card>
    <Space gap={12} right>
      <Button link action={onReset} disabled={!canReset}>{i18n('form.Reset')}</Button>
      <Button primary action={onSave} disabled={!canSave}>{i18n('common.Save')}</Button>
    </Space>
  </Vertical>
})
