import { TagsFormInput } from 'component/Select/TagsFormInput'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { ProductStore } from 'store/product'

type Props = {
  product: ProductStore
}

export const TagsInput = observer(({ product }: Props) => {
  const { tags, original, busy } = product.tags
  const suggestions = home.collection.it.tags

  const onChange = useLatestCallback((value: string[]) => {
    tags.value = value
  })

  const onUndo = useLatestCallback(() => {
    product.tags.undo()
  })

  const onSave = useLatestCallback(async () => {
    if (!busy) await product.saveTags()
  })

  return <TagsFormInput value={tags.value} original={original} placeholder={i18n('label.Tags')} busy={busy}
    suggestions={suggestions} onChange={onChange} onUndo={onUndo} onSave={onSave} />
})
