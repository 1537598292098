import { Card } from 'component/Card'
import { SearchInput } from 'component/Input/SearchInput'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { GroupRow } from 'saas/page/groups/components/GroupRow'
import { groupsSaas } from 'saas/store/groups/GroupsCache'
import css from './GroupsTable.module.scss'

export const GroupsTable = observer(() => {
  const { filter, filteredGroups } = groupsSaas.it
  const empty = !filteredGroups.length

  const onChangeFilter = useLatestCallback((value: string) => {
    groupsSaas.it.filter = value
  })

  return <Card pad={24} fit vertical>
    <SearchInput value={filter} onChange={onChangeFilter}
      placeholder={i18n('placeholder.Search')} />
    <Gap height={16} />
    <table className={css.table}>
      <thead>
      <tr>
        <td>{i18n('label.Name')}</td>
        <td>{i18n('group.Conditions')}</td>
      </tr>
      </thead>
      <tbody>
      {filteredGroups.map(group => <GroupRow key={group.group_id} group={group} />)}
      {empty && <tr>
        <td colSpan={2}>
          <Typo size={14} tertiary>
            {i18n('group.NoItemGroups')}
          </Typo>
        </td>
      </tr>}
      </tbody>
    </table>
  </Card>
})
