import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ColorsTab } from 'saas/page/product/tryon/SettingsCard/ColorsTab'
import { GeneralTab } from 'saas/page/product/tryon/SettingsCard/GeneralTab'
import { TabSelect } from 'saas/page/product/tryon/SettingsCard/TabSelect'
import { TextsTab } from 'saas/page/product/tryon/SettingsCard/TextsTab'
import { tryon } from 'saas/store/product/ProductSaasController'

export const SettingsCard = observer(() => {
  const { tab, busy } = tryon.it
  const { canSave } = tryon.it.edit

  const onSave = useLatestCallback(() => {
    void tryon.it.saveSettings()
  })

  return <Card vertical gap={24} pad={16} width={400} opposite secondary>
    <Vertical>
      <Space gap={16} opposite>
        <Typo size={16} bold>{i18n('label.Settings')}</Typo>
        <TabSelect />
      </Space>
      <Gap height={16} />
      {tab === 'general' && <GeneralTab />}
      {tab === 'colors' && <ColorsTab />}
      {tab === 'texts' && <TextsTab />}
    </Vertical>
    <Space right>
      <Button primary disabled={busy || !canSave} action={onSave}>
        {i18n('common.Save')}
      </Button>
    </Space>
  </Card>
})
