import { Checkbox } from 'component/Checkbox'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'

export const Condition = observer(() => {
  const { value, onChange } = groupFormSaas.it.condition

  const onChangeAll = useLatestCallback(() => {
    onChange('all')
  })

  const onChangeAny = useLatestCallback(() => {
    onChange('any')
  })

  return <Space gap={16}>
    <Typo>{i18n('group.conditions.ProductsMustMatch')}</Typo>
    <Space gap={8}>
      <Checkbox checked={value === 'all'} onChange={onChangeAll}>{i18n('group.conditions.AllConditions')}</Checkbox>
      <Checkbox checked={value === 'any'} onChange={onChangeAny}>{i18n('group.conditions.AnyCondition')}</Checkbox>
    </Space>
  </Space>
})
