// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagsList-title-7d3c{padding-right:4px}.TagsList-tag-7d3c{background-color:var(--ui-background-color-soft);padding:0 4px;border-radius:2px;overflow:hidden;text-overflow:ellipsis}`, "",{"version":3,"sources":["webpack://./src/page/internal/products/tags/TagsList.module.scss"],"names":[],"mappings":"AAAA,qBACE,iBAAA,CAGF,mBACE,gDAAA,CACA,aAAA,CACA,iBAAA,CACA,eAAA,CACA,sBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `TagsList-title-7d3c`,
	"tag": `TagsList-tag-7d3c`
};
module.exports = ___CSS_LOADER_EXPORT___;
