import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { productsConfig } from 'saas/store/products/ProductsConfigStore'
import { ProductsSaasStore } from 'saas/store/products/ProductsSaasStore'
import { AsyncStoreController } from 'store/base/async/AsyncStoreController'

async function preload(company_id: string) {
  await companiesSaas.load()
  const company = companiesSaas.companies.find(c => c.company_id === company_id)
  if (company) await company.load()
  return company
}

export async function factory(company_id: string): Promise<ProductsSaasStore | undefined> {
  const _company = preload(company_id)
  const _config = productsConfig.load(company_id).load()
  const company = await _company
  if (!company) return
  const config = await _config
  if (!config) return
  return new ProductsSaasStore(company_id, config)
}

export const productsSaas = new AsyncStoreController(factory)
