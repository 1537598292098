import { makeAutoObservable } from 'mobx'
import { mx } from 'store/base/mx'
import { ItemGroup } from 'type/ItemGroup'

export class GroupStore {
  private _json: ItemGroup

  constructor(json: ItemGroup) {
    makeAutoObservable(this)
    this._json = json
  }

  get json(): ItemGroup {
    return this._json
  }

  set json(value: ItemGroup) {
    this._json = value
  }

  get company_id(): string {
    return this._json.company_id
  }

  get group_id(): string {
    return this._json.group_id
  }
}

export const groupSaas = mx.ref<GroupStore>()
