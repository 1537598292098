// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupsTable-table-da74{width:fit-content;border-collapse:collapse;margin:0 -8px}.GroupsTable-table-da74 td:nth-child(1){width:300px}.GroupsTable-table-da74 td:nth-child(2){width:600px}.GroupsTable-table-da74 thead td{white-space:nowrap;vertical-align:top;padding:12px 8px;font-size:14px;color:var(--ui-text-color-secondary)}.GroupsTable-table-da74 tbody td{vertical-align:top;padding:12px 8px;border-top:1px solid var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/saas/page/groups/components/GroupsTable.module.scss"],"names":[],"mappings":"AAAA,wBACE,iBAAA,CACA,wBAAA,CACA,aAAA,CAIA,wCACE,WAAA,CAGF,wCACE,WAAA,CAWF,iCACE,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,cAAA,CACA,oCAAA,CAKF,iCACE,kBAAA,CACA,gBAAA,CACA,oDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `GroupsTable-table-da74`
};
module.exports = ___CSS_LOADER_EXPORT___;
