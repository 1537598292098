import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Tag } from 'component/Tag'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { getProductTypeTitle } from 'type/product/ProductType'
import { nbsp } from 'util/typo'
import css from 'saas/page/products/components/ProductCard.module.scss'

type Props = {
  product: ProductSaasStore
}

export const ProductCard = observer(({ product }: Props) => {
  const { company_id } = productsSaas.it.config
  const { product_id, type, name, published } = product.json
  const path = `/company/${company_id}/product/${product_id}`

  return <Card width={240} vertical tertiary>
    <Vertical pad={16}>
      <Space right>
        <Tag success={published}>
          {published ? i18n('product.Published') : i18n('product.NotPublished')}
        </Tag>
      </Space>
      <Gap height={48} />
      <Typo size={10}>
        {getProductTypeTitle(type)}
      </Typo>
      <Gap height={4} />
      <Typo size={16} wrap>
        {name}
      </Typo>
      <Gap height={12} />
      <Typo size={12}>
        {nbsp}
      </Typo>
    </Vertical>
    <Vertical className={css.bottom}>
      <Link path={path}>
        <Button text sharp height={40} wide>
          <Space opposite>
            <Typo>
              Manage
            </Typo>
            <Icon name="keyboard_arrow_right" />
          </Space>
        </Button>
      </Link>
    </Vertical>
  </Card>
})
