import { IconButton } from 'component/Button/IconButton'
import { Checkbox } from 'component/Checkbox'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DemoCatalogButton } from 'page/demo-catalog/DemoCatalogButton/DemoCatalogButton'
import { ui } from 'store/home/ui/HomeUiStore'
import css from './FilterPopover.module.scss'

type Props = {
  title: string
  list: string[]
  value: string[]
  onChange: (value: string[]) => void
  onClose: () => void
}

export const FilterPopover = observer(({ title, list, value, onChange, onClose }: Props) => {
  const { mobile } = ui.window

  const onChangeItem = useLatestCallback((key: string) => {
    let next
    if (value.includes(key)) next = value.filter(it => it !== key)
    else next = [...value, key]
    onChange(next)
  })

  const onReset = useLatestCallback(() => {
    onChange([])
    onClose()
  })

  const onClickSubmit = useLatestCallback(() => {
    onClose()
  })

  return <Vertical className={css.popover}>
    {mobile && <Space className={css.header} gap={12} pad={[0, 0]} height={40} opposite>
      <Typo size={14} medium upper>{title}</Typo>
      <IconButton small action={onClickSubmit}>
        <Icon name="close" size={24} />
      </IconButton>
    </Space>}

    <Vertical className={css.scroll} gap={12} pad={[12, 0]}>
      {list.map((item: string) => {
        const checked = value.includes(item)
        return <Space key={item} gap={12}>
          <Checkbox className={css.checkbox} checked={checked} onChange={() => onChangeItem(item)}>
            {item}
          </Checkbox>
        </Space>
      })}
    </Vertical>

    <Space className={css.footer} gap={12} pad={[12, 0]} spread wide wrap>
      <DemoCatalogButton text action={onReset}>
        {i18n('demo.ResetAll')}
      </DemoCatalogButton>
      {mobile && <DemoCatalogButton className={css.submit} invert action={onClose}>
        {i18n('demo.Apply')}
      </DemoCatalogButton>}
    </Space>
  </Vertical>
})
