import { Select as SelectAntd } from 'antd'
import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { renderSelectTag } from 'component/Select/SelectTag'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import type { BaseSelectRef } from 'rc-select/lib/BaseSelect'
import { KeyboardEvent, useEffect, useMemo, useState } from 'react'
import { uniq } from 'util/array'
import { fixText } from 'util/form'
import { getShortcut } from 'util/keyboard'
import { equal } from 'util/object'
import { matchSearch } from 'util/search'
import { mutable } from 'util/type'
import css from './TagsFormInput.module.scss'

type Props = {
  busy?: boolean
  className?: string
  disabled?: boolean
  error?: boolean
  onChange(value: readonly  string[]): void
  onSave(): void
  onUndo(): void
  original: readonly string[]
  placeholder?: string | undefined
  popupClassName?: string
  suggestions: string[]
  value: readonly string[]
}

function buildNextValue(value: readonly string[], text: string) {
  text = fixText(text)
  const tags = value
  return text && !tags.includes(text) ? [...tags, text] : tags
}

export function TagsFormInput(props: Props) {
  const { busy, error, original, placeholder, suggestions, value } = props

  const [input, setInput] = useState<HTMLInputElement>()
  const [_focus, setFocus] = useState(false)
  const [text, setText] = useState('')

  const disabled = props.disabled || busy
  const status = error ? 'error' : undefined
  const next = buildNextValue(value, text)
  const changed = !equal(original, next)
  const form = !disabled && changed

  const options = useMemo(() => {
    const search = fixText(text)
    const found = search ? suggestions.filter(tag => matchSearch(search, [tag])) : suggestions
    const filtered = found.filter(tag => tag !== search && !value.includes(tag))
    const fin = search ? [search, ...filtered] : filtered
    return fin.map(value => ({ value })).slice(0, 8)
  }, [suggestions, text, value])

  const className = clsx(props.className, css.select, busy && css.busy, form && css.form)
  const popupClassName = clsx(props.popupClassName, css.popup)

  const onRef = useLatestCallback((ref: BaseSelectRef | null) => {
    setInput(ref?.nativeElement?.querySelector('input') ?? undefined)
  })

  const onChange = useLatestCallback((value: string[]) => {
    setText('')
    value = uniq(value.map(fixText).filter(s => !!s))
    props.onChange(value)
  })

  const onUndo = useLatestCallback(() => {
    setText('')
    props.onUndo()
  })

  const onSave = useLatestCallback(() => {
    setText('')
    props.onChange(next)
    props.onSave()
  })

  const onBlur = useLatestCallback(() => {
    setText('')
  })

  const onSearch = useLatestCallback((value: string) => {
    setText(value.replaceAll('\0', ''))
  })

  const onKeyDown = useLatestCallback((event: KeyboardEvent) => {
    if (disabled) return
    const shortcut = getShortcut(event)
    if (shortcut === 'Ctrl+Enter') {
      onSave()
    }
  })

  useEffect(() => {
    if (!input) return

    function focus() {
      setFocus(true)
    }

    function blur() {
      setFocus(false)
    }

    input.addEventListener('focus', focus)
    input.addEventListener('blur', blur)
    return () => {
      input.removeEventListener('focus', focus)
      input.removeEventListener('blur', blur)
    }
  }, [input])

  const suffixIcon = <Space gap={3} tall bottom>
    {busy && <Space width={24} height={24} center>
      <Spinner size={16} />
    </Space>}
    {form && <IconButton className={css.button} small
      action={onUndo} disabled={disabled}>
      <Icon name="undo" size={16} />
    </IconButton>}
    {form && <IconButton className={css.button} small
      action={onSave} disabled={disabled} title={'Ctrl+Enter'}>
      <Icon name="check" size={16} />
    </IconButton>}
  </Space>

  return <SelectAntd
    allowClear={false}
    className={className}
    disabled={disabled}
    mode="multiple"
    notFoundContent={false}
    onBlur={onBlur}
    onChange={onChange}
    onKeyDown={onKeyDown}
    onSearch={onSearch}
    options={options}
    placeholder={placeholder}
    popupClassName={popupClassName}
    ref={onRef}
    showSearch
    searchValue={text}
    status={status}
    suffixIcon={suffixIcon}
    tagRender={renderSelectTag}
    value={mutable(value)}
  />
}
