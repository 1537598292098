import { Button } from 'component/Button'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { TryOnSettingsTab, tryOnSettingsTabs } from 'saas/store/product/tryon/TryOnProductStore'

function label(tab: TryOnSettingsTab): string {
  if (!tab) return 'NONE'
  switch (tab) {
    case 'general':
      return i18n('widget.GeneralTab')
    case 'colors':
      return i18n('widget.ColorsTab')
    case 'texts':
      return i18n('widget.TextsTab')
    default:
      console.error('unknown tab', tab)
      return ('' + tab).toUpperCase()
  }
}

export const TabSelect = observer(() => {
  const { tab: selected } = tryon.it

  const options = tryOnSettingsTabs.map<DropdownItem>(tab => ({
    key: tab,
    label: <Typo primary={tab === selected}>{label(tab)}</Typo>,
    action: () => tryon.it.tab = tab,
  }))

  return <div>
    <Dropdown placement="bottomRight" items={options}>
      <Button text compact>
        <Space gap={2} pad={[0, 8]} height={32}>
          <Typo size={14}>{label(selected)}</Typo>
          <Icon name="keyboard_arrow_down" />
        </Space>
      </Button>
    </Dropdown>
  </div>
})
