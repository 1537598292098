import { GroupsStore } from 'saas/store/groups/GroupsStore'
import { api } from 'store/api'
import { AsyncStoreController } from 'store/base/async/AsyncStoreController'

export async function factory(company_id: string): Promise<GroupsStore | undefined> {
  const groups = await api.getItemGroups(company_id)
  return new GroupsStore(company_id, groups)
}

export const groupsSaas = new AsyncStoreController(factory)
