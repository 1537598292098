import { makeAutoObservable } from 'mobx'
import { GroupStore } from 'saas/store/groups/GroupStore'
import { mx } from 'store/base/mx'

export class GroupDeleteModalStore {
  readonly group: GroupStore
  private _accept = false

  constructor(group: GroupStore) {
    makeAutoObservable(this)
    this.group = group
  }

  get canDelete(): boolean {
    return this._accept
  }

  get accept(): boolean {
    return this._accept
  }

  set accept(value: boolean) {
    this._accept = value
  }
}

export const groupDeleteModal = mx.ref<GroupDeleteModalStore>()
