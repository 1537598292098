import { uniqBy } from 'lodash'
import { ExternalStatus, getReviewStatusLabel, reviewStatusValues } from 'type/ExternalStatus'
import { genders, getGenderLabel } from 'type/Gender'
import { getItemStatusLabel, InternalStatus, itemStatusValues } from 'type/InternalStatus'
import { FilterName } from 'type/ItemGroupFilter'
import { Comparator, comparing } from 'util/compare'
import { compareIgnoreCase, comparingKnownStrings } from 'util/string'

export type GroupFilterAction = {
  val: string
  title: string
}

export type GroupFilterValue = {
  val: string
  title: string
}

export type GroupFilterConfig = {
  title: string
  name: string
  type: 'list' | 'text' | string
  actions: GroupFilterAction[]
  values: GroupFilterValue[]
}

export function getFilterValueLabel(val: GroupFilterValue, name: FilterName | undefined): string {
  if (name === 'collection') {
    return val.title
  } else if (name === 'internal_status') {
    return getItemStatusLabel(val.val as InternalStatus)
  } else if (name === 'external_status') {
    return getReviewStatusLabel(val.val as ExternalStatus)
  } else if (name === 'gender') {
    return getGenderLabel(val.val)
  } else {
    return val.title
  }
}

export function getFilterValueComparator(name: string): Comparator<GroupFilterValue> {
  if (name === 'collection') {
    return comparing(val => val.title, compareIgnoreCase)
  } else if (name === 'internal_status') {
    return comparing(val => val.val, comparingKnownStrings(itemStatusValues, []))
  } else if (name === 'external_status') {
    return comparing(val => val.val, comparingKnownStrings(reviewStatusValues, []))
  } else if (name === 'gender') {
    return comparing(val => val.val, comparingKnownStrings(genders, []))
  } else {
    return comparing(val => val.title, compareIgnoreCase)
  }
}

export function extractFilterValues(config: GroupFilterConfig): GroupFilterValue[] {
  const comparator = getFilterValueComparator(config.name)
  const x = uniqBy(config.values, val => val.val).sort(comparator)
  return x
}
