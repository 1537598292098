import { Button } from 'component/Button'
import { Checkbox } from 'component/Checkbox'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupDeleteModal } from 'saas/store/groups/GroupDeleteModalStore'
import { groupsSaas } from 'saas/store/groups/GroupsCache'

export const GroupDeleteModal = observer(() => {
  const { group, accept, canDelete } = groupDeleteModal.it
  const { group_name } = group.json

  const onAccept = useLatestCallback((value: boolean) => {
    groupDeleteModal.it.accept = value
  })

  const onCancel = useLatestCallback(() => {
    groupDeleteModal.close()
  })

  const onDelete = useLatestCallback(() => {
    groupsSaas.it.removeGroup(group)
    groupDeleteModal.close()
  })

  return <Modal title={i18n('group.DeleteGroup')} onCancel={onCancel}>
    <Vertical gap={12}>
      <Typo size={14}>
        {i18n('group.YouAreAboutToDeleteName', { n: <Typo size={14} secondary />, name: group_name })}
      </Typo>
      <Checkbox checked={accept} onChange={onAccept}>
        <Typo error>
          {i18n('group.DeleteConfirmationWarning')}
        </Typo>
      </Checkbox>
    </Vertical>
    <Space gap={12} right wrap>
      <Button link action={onCancel}>{i18n('common.Cancel')}</Button>
      <Button disabled={!canDelete} error action={onDelete}>{i18n('common.Delete')}</Button>
    </Space>
  </Modal>
})
