import { i18n } from 'i18n'
import { MemberRole } from 'type/MemberRole'

export enum MemberRight {
  ViewCollectionsAndItems = 'ViewCollectionsAndItems',
  ManageItemStatuses = 'ManageItemStatuses',
  TryOnWidget = 'TryOnWidget',
  AddAndEditItems = 'AddAndEditItems',
  ViewCompanyInformation = 'ViewCompanyInformation',
  ViewStatistics = 'ViewStatistics',
  ViewApiKeys = 'ViewApiKeys',
  ManageApiKeys = 'ManageApiKeys',
  ManageProducts = 'ManageProducts',
  ManageGroups = 'ManageGroups',
  ViewDocumentation = 'ViewDocumentation',
  EditCompanyInformation = 'EditCompanyInformation',
  DeleteCompany = 'DeleteCompany',
  InviteUsers = 'InviteUsers',
  ManageUsersAndRoles = 'ManageUsersAndRoles',
  DeleteMembers = 'DeleteMembers',
  CreateCollections = 'CreateCollections',
  EditCollectionInformation = 'EditCollectionInformation',
  DeleteCollections = 'DeleteCollections',
  DeleteItems = 'DeleteItems',
}

export function getRoleRights(role: MemberRole): MemberRight[] {
  switch (role) {
    case MemberRole.reviewer:
      return [
        MemberRight.ViewCollectionsAndItems,
        MemberRight.ManageItemStatuses,
        MemberRight.TryOnWidget,
        MemberRight.AddAndEditItems,
        MemberRight.ViewCompanyInformation,
        MemberRight.ViewStatistics,
      ]
    case MemberRole.developer:
      return [
        MemberRight.ViewCollectionsAndItems,
        MemberRight.TryOnWidget,
        MemberRight.ViewCompanyInformation,
        MemberRight.ViewApiKeys,
        MemberRight.ManageApiKeys,
        MemberRight.ManageProducts,
        MemberRight.ManageGroups,
        MemberRight.ViewDocumentation,
        MemberRight.ViewStatistics,

      ]
    case MemberRole.admin:
      return [
        MemberRight.ViewCollectionsAndItems,
        MemberRight.ManageItemStatuses,
        MemberRight.TryOnWidget,
        MemberRight.AddAndEditItems,
        MemberRight.CreateCollections,
        MemberRight.EditCollectionInformation,
        MemberRight.DeleteCollections,
        MemberRight.DeleteItems,
        MemberRight.ViewCompanyInformation,
        MemberRight.EditCompanyInformation,
        MemberRight.DeleteCompany,
        MemberRight.InviteUsers,
        MemberRight.ManageUsersAndRoles,
        MemberRight.DeleteMembers,
        MemberRight.ViewApiKeys,
        MemberRight.ManageApiKeys,
        MemberRight.ManageProducts,
        MemberRight.ManageGroups,
        MemberRight.ViewDocumentation,
        MemberRight.ViewStatistics,
      ]
    default:
      return []
  }
}

export function getRoleRightLabel(right: MemberRight): string {
  switch (right) {
    case MemberRight.ViewCollectionsAndItems:
      return i18n('right.ViewCollectionsAndItems')
    case MemberRight.ManageItemStatuses:
      return i18n('right.ManageItemStatuses')
    case MemberRight.TryOnWidget:
      return i18n('right.TryOnWidget')
    case MemberRight.AddAndEditItems:
      return i18n('right.AddAndEditItems')
    case MemberRight.ViewCompanyInformation:
      return i18n('right.ViewCompanyInformation')
    case MemberRight.ViewStatistics:
      return i18n('right.ViewStatistics')
    case MemberRight.ViewApiKeys:
      return i18n('right.ViewApiKeys')
    case MemberRight.ManageApiKeys:
      return i18n('right.ManageApiKeys')
    case MemberRight.ManageProducts:
      return i18n('right.ManageProducts')
    case MemberRight.ManageGroups:
      return i18n('right.ManageGroups')
    case MemberRight.ViewDocumentation:
      return i18n('right.ViewDocumentation')
    case MemberRight.EditCompanyInformation:
      return i18n('right.EditCompanyInformation')
    case MemberRight.DeleteCompany:
      return i18n('right.DeleteCompany')
    case MemberRight.InviteUsers:
      return i18n('right.InviteUsers')
    case MemberRight.ManageUsersAndRoles:
      return i18n('right.ManageUsersAndRoles')
    case MemberRight.DeleteMembers:
      return i18n('right.DeleteMembers')
    case MemberRight.CreateCollections:
      return i18n('right.CreateCollections')
    case MemberRight.EditCollectionInformation:
      return i18n('right.EditCollectionInformation')
    case MemberRight.DeleteCollections:
      return i18n('right.DeleteCollections')
    case MemberRight.DeleteItems:
      return i18n('right.DeleteItems')
    default:
      return ('' + right).toUpperCase()
  }
}

export function getRoleRightLabels(role: MemberRole): string[] {
  return getRoleRights(role).map(right => getRoleRightLabel(right))
}
