import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'

export const Name = observer(() => {
  const { name } = groupFormSaas.it

  return <TextField field={name} label={i18n('label.Name')} />
})
