import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { makeAutoObservable, runInAction } from 'mobx'
import { api } from 'store/api'
import { getNextId } from 'util/id'

async function toBase64(file: File) {
  const text = await file.text()
  const base = btoa(text)
  return 'data:image/svg+xml;base64,' + base
}

export class UploadImageFile {
  readonly id = getNextId()
  private _url: string | undefined
  private _file: File | undefined
  private _resource: string | undefined

  constructor() {
    makeAutoObservable(this)
  }

  set value(url: string | undefined) {
    this._url = url
    this.setFileInner(undefined)
  }

  set file(file: File | undefined) {
    this._url = undefined
    this.setFileInner(file)
    if (file) void this.upload(file)
  }

  get value(): string | undefined {
    return this._url
  }

  get busy(): boolean {
    return !!this._file
  }

  get src(): string | undefined {
    if (this._resource) return this._resource
    return this._url
  }

  private async upload(file: File) {
    try {
      const svg = file.type.includes('svg')
      const url = svg ? await toBase64(file) : await api.uploadImage(file)
      if (this._file === file) {
        runInAction(() => {
          this._url = url
          this.setFileInner(undefined)
        })
      }
    } catch (e) {
      console.error('upload failed', e)
      if (this._file === file) {
        notice.error(i18n('error.ImageUploadFailed'))
        runInAction(() => {
          this.setFileInner(undefined)
        })
      }
    }
  }

  private setFileInner(file: File | undefined) {
    this._file = file
    if (this._resource) URL.revokeObjectURL(this._resource)
    if (file) this._resource = URL.createObjectURL(file)
  }
}
