import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

const LIMITS = [10, 20, 50, 100]

export const Limit = observer(() => {
  const store = groupPreviewSaas.it

  const options = LIMITS.map<DropdownItem>(limit => ({
    key: limit.toFixed(),
    label: <Typo primary={store.limit === limit}>{limit}</Typo>,
    action: store.busy ? false : async () => {
      store.limit = limit
    },
  }))

  return <Space oh>
    <Typo secondary ellipsis>{i18n('search.ItemsPerPage')}</Typo>
    <Dropdown placement="topLeft" items={options}>
      <Space gap={2} pad={[0, 8]} height={32}>
        <Typo>{store.limit}</Typo>
        <Icon name="keyboard_arrow_down" />
      </Space>
    </Dropdown>
  </Space>
})
