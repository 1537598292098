import { production } from 'app/config/constants'
import { makeAutoObservable, observable } from 'mobx'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { NameEditStore } from 'saas/store/product/basic/NameEditStore'
import { KeyEditStore } from 'saas/store/product/tryon/KeyEditStore'
import { TryOnEditStore } from 'saas/store/product/tryon/TryOnEditStore'
import { productsConfig } from 'saas/store/products/ProductsConfigStore'
import { TryOnProduct } from 'type/product/TryOnProduct'
import { json5 } from 'util/json5'
import { omit, updateObject } from 'util/object'

function assembleOptions(company: string, json: TryOnProduct): object {
  const { lang, access } = json
  const { on, delay, timeout, title, description, agreement, thanks } = json.email ?? {}

  const email = !on ? undefined : omit({
    on,
    delay,
    timeout,
    title,
    description,
    agreement,
    thanks,
  }, v => v == null || v === '')

  const options = omit({
    company,
    access,
    lang,
    email,
  }, v => v == null || v === '')

  return options
}

function assembleCode(company: string, json: TryOnProduct) {
  const host = production ? 'try-on.showoff.app' : 'try-on-stage.show-off.org'
  const options = assembleOptions(company, json)
  const optionsJson = json5.stringify(options, { space: 2 }).replaceAll('\n', '\n  ')

  // language=html
  return `
<script>
  function setupShowoff(options) {
    const queue = []
    function open(parameters) {
      queue.push(['open', parameters])
    }
    window.showoff = { queue, open, options }
  }
  setupShowoff(${optionsJson})
</script>

<script async src="https://${host}/widget.js"></script>
`.trim()
}

export const tryOnSettingsTabs = ['general', 'colors', 'texts'] as const
export type TryOnSettingsTab = typeof tryOnSettingsTabs[number]

export class TryOnProductStore {
  readonly company_id: string
  private _json: TryOnProduct
  private _tab: TryOnSettingsTab = 'general'
  private _edit!: TryOnEditStore
  private _nameEdit?: NameEditStore
  private _keyEdit?: KeyEditStore

  constructor(company_id: string, json: TryOnProduct) {
    this.company_id = company_id
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this._edit = new TryOnEditStore(this._json)
  }

  get busy(): boolean {
    const config = productsConfig.get(this.company_id)
    return config.busy
  }

  get code(): string {
    return assembleCode(this.company_id, this.json)
  }

  get optionsPreview(): object {
    const { updates } = this.edit
    const json = updateObject(this._json, updates)
    return assembleOptions(this.company_id, json)
  }

  get product_id(): string {
    return this._json.product_id
  }

  findKey(value: string | undefined): ApiKeyStore | undefined {
    return value ? this.company.keys.find(k => k.value === value) : undefined
  }

  get key(): ApiKeyStore | undefined {
    return this.findKey(this.json.access)
  }

  get json(): TryOnProduct {
    return this._json
  }

  get company(): CompanySaasStore {
    const company = companiesSaas.companies.find(c => c.company_id === this.company_id)
    if (!company) throw new Error('no company')
    return company
  }

  get tab(): TryOnSettingsTab {
    return this._tab
  }

  set tab(value: TryOnSettingsTab) {
    this._tab = value
  }

  get edit(): TryOnEditStore {
    return this._edit
  }

  get nameEdit(): NameEditStore | undefined {
    return this._nameEdit
  }

  openNameEdit() {
    this._nameEdit = new NameEditStore(this._json)
  }

  closeNameEdit() {
    this._nameEdit = undefined
  }

  get keyEdit(): KeyEditStore | undefined {
    return this._keyEdit
  }

  openKeyEdit() {
    const key = this.key
    this._keyEdit = new KeyEditStore({ key })
  }

  closeKeyEdit() {
    this._keyEdit = undefined
  }

  async saveSettings() {
    const { updates } = this.edit
    this.json = updateObject(this._json, updates)
    await this.save()
  }

  async saveName() {
    const nameEdit = this.nameEdit
    if (!nameEdit || !nameEdit.check()) return
    const update = nameEdit.buildUpdate()
    this.json = updateObject(this._json, update)
    this.closeNameEdit()
    await this.save()
  }

  async saveKey() {
    const keyEdit = this.keyEdit
    if (!keyEdit || !keyEdit.check()) return
    const update = keyEdit.buildUpdate()
    this.json = updateObject(this._json, update)
    this.closeKeyEdit()
    await this.save()
  }

  async publish(publish: boolean) {
    if (!!this.json.published === publish) return
    const published = publish ? true : undefined
    const update = { published }
    this.json = updateObject(this._json, update)
    await this.save()
  }

  close() {
    this._tab = 'general'
    this._edit = new TryOnEditStore(this._json)
    this._keyEdit = undefined
    this._nameEdit = undefined
  }

  private set json(json: TryOnProduct) {
    json.updated_at = new Date().toISOString()
    this._json = json
    this.edit.json = this.json
  }

  private async save() {
    const config = productsConfig.get(this.company_id)
    await config.updateProduct(this.json)
  }
}
