import { clsx } from 'clsx'
import { CSSProperties, ReactNode } from 'react'
import css from './Typo.module.scss'

type Props = {
  balance?: boolean
  bold?: boolean
  center?: boolean
  children?: ReactNode
  className?: string
  code?: boolean
  ellipsis?: boolean
  error?: boolean
  first?: boolean
  medium?: boolean
  mono?: boolean
  nowrap?: boolean
  one?: boolean
  pre?: boolean
  primary?: boolean
  secondary?: boolean
  semi?: boolean
  size?: 10 | 11 | 12 | 13 | 14 | 16 | 18 | 20 | 24
  style?: CSSProperties
  tertiary?: boolean
  title?: string
  upper?: boolean
  wrap?: boolean
}

export function Typo(props: Props) {
  const {
    balance, bold, center, children, code, ellipsis, error, first, medium, mono, nowrap, one,
    pre, primary, secondary, semi, size, style, tertiary, title, upper, wrap,
  } = props
  const className = clsx(
    props.className,
    size && css[`size${size}`],
    medium && css.medium,
    semi && css.semi,
    bold && css.bold,
    mono && css.mono,
    center && css.center,
    one && css.one,
    upper && css.upper,
    code && css.code,
    pre && css.pre,
    nowrap && css.nowrap,
    wrap && css.wrap,
    balance && css.balance,
    primary && css.primary,
    ellipsis && css.ellipsis,
    error && css.error,
    first && css.first,
    secondary && css.secondary,
    tertiary && css.tertiary,
  )

  return <span className={className} style={style} title={title}>
    {children}
  </span>
}
