import { production } from 'app/config/constants'
import { lang } from 'app/controller/language'
import { i18n } from 'i18n'
import { makeAutoObservable, reaction } from 'mobx'
import { RouteConfig, RouteStore } from 'saas/route/RouteStore'
import { can } from 'store/can'
import { home } from 'store/home'
import { me } from 'store/me'
import { compact } from 'util/array'

const cache: RouteStore[] = []

function route(config: RouteConfig): RouteStore {
  const existing = cache.find(store => store.path === config.path)
  return existing ?? new RouteStore(config)
}

class RoutesStore {
  private _routes: RouteStore[] = []

  constructor() {
    makeAutoObservable(this)
    reaction(() => this.assembleRoutes(), routes => this._routes = routes, { fireImmediately: true })
  }

  get routes(): RouteStore[] {
    return this._routes
  }

  private assembleRoutes(): RouteStore[] {
    if (!lang.ready) return []
    if (!me.present) return []

    const company_id = home.company.optional?.company_id
    if (!company_id) return []

    const docs = compact([
      route({
        icon: 'notes',
        title: i18n('title.Api'),
        path: '/docs/api',
      }),
      route({
        icon: 'notes',
        title: i18n('title.Widget'),
        path: '/docs/widget',
      }),
      (!production && route({
        icon: 'notes',
        title: i18n('title.NewApi'),
        path: '/docs/new.api',
      })),
    ])

    const routes = []
    routes.push(route({
      icon: 'insert_chart',
      title: i18n('title.Dashboard'),
      path: '/',
    }))
    if (can.ManageProducts(company_id)) routes.push(route({
      icon: 'tune',
      title: i18n('title.Products'),
      path: `/company/${company_id}/products`,
      patterns: ['/company/*/products', '/company/*/product/*'],
    }))
    if (can.ManageGroups(company_id)) routes.push(route({
      icon: 'storefront',
      title: i18n('title.ItemGroups'),
      path: `/company/${company_id}/groups`,
      patterns: ['/company/*/groups', '/company/*/group/*'],
    }))
    routes.push(route({
      icon: 'shopping_bag',
      title: i18n('collection.Collections'),
      path: `/company/${company_id}`,
      patterns: ['/company/*', '/collection/*'],
    }))
    routes.push(route({
      icon: 'search',
      title: i18n('title.ItemSearch'),
      path: '/search',
    }))
    routes.push(route({
      icon: 'work',
      title: i18n('label.Companies'),
      path: '/companies',
      patterns: ['/companies', '/companies/create', '/company/*/manage'],
    }))
    routes.push(route({
      icon: 'book',
      title: i18n('title.Docs'),
      path: '/docs',
      children: docs,
    }))
    return routes
  }
}

export const routesStore = new RoutesStore()
