import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupsSaas } from 'saas/store/groups/GroupsCache'

export const Title = observer(() => {
  const { busy } = groupsSaas.it

  const onCreate = useLatestCallback(() => {
    void groupsSaas.it.createGroup()
  })

  return <Space gap={16} opposite>
    <Typo size={18} bold>
      {i18n('title.ItemGroups')}
    </Typo>
    <Button disabled={busy} action={onCreate}>
      {i18n('group.CreateGroup')}
    </Button>
  </Space>
})
