import { Option, Select } from 'component/Select/Select'
import { useFocus } from 'hook/useFocus'
import { ReactNode } from 'react'
import { matchSearch } from 'util/search'
import { ensureString } from 'util/string'
import css from './SearchSelect.module.scss'

export type SelectOption<Value = string, Data = unknown> = {
  className?: string
  disabled?: boolean
  label?: ReactNode
  options?: undefined
  value: Value
  data?: Data
}

export type SelectGroup = {
  label?: ReactNode
  options: SelectOption[]
}

type Base = string | readonly string[]

type Props<Value extends Base, Data = unknown> = {
  disabled?: boolean
  error?: boolean
  groups?: SelectGroup[]
  multiple?: boolean
  onChange?(value: Value | undefined | Value[], option?: SelectOption<Value, Data>): void
  onFocus?(focus: boolean): void
  options?: SelectOption<Value, Data>[]
  placeholder?: string
  value: Value | undefined | readonly Value[]
  wide?: boolean
}

function filterOption(search: string, option: SelectOption | undefined) {
  const label = ensureString(option?.label || option?.value)
  return matchSearch(search, [label])
}

export function SearchSelect<Value extends Base>(props: Props<Value>) {
  const { disabled, error, groups, multiple, onChange, options, placeholder, value, wide } = props
  const focus = useFocus(props)

  const status = error ? 'error' : undefined
  const mode = multiple ? 'multiple' : undefined
  const _options: Option<unknown, Value>[] = groups ?? options ?? []

  return <Select className={css.select}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wide={wide} value={value as any} options={_options as any} onChange={onChange as any}
    mode={mode} status={status} filterOption={filterOption} showSearch placeholder={placeholder} disabled={disabled}
    onBlur={focus.onBlur} onFocus={focus.onFocus} />
}
