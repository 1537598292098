import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable } from 'mobx'
import { StringStore } from 'store/base/StringStore'
import { StringTypedStore } from 'store/base/StringTypedStore'
import { FilterAction, FilterName, ItemGroupFilter } from 'type/ItemGroupFilter'
import { fixText } from 'util/form'
import { getNextKey } from 'util/id'

export class GroupFilterStore {
  readonly key = getNextKey()
  readonly form = new FormStore()
  readonly type = this.form.field(new StringTypedStore<FilterName>(), { required: true })
  readonly action = this.form.field(new StringTypedStore<FilterAction>(), { required: true })
  readonly value = this.form.field(new StringStore(), { required: true, fix: fixText })

  constructor(json?: ItemGroupFilter) {
    makeAutoObservable(this)
    if (json) this.applyJson(json)
  }

  toJson(): ItemGroupFilter | undefined {
    const type = this.type.value
    const action = this.action.value
    const value = this.value.value
    const good = type && action && value
    if (!good) return undefined
    return { type, action, value }
  }

  private applyJson(json: ItemGroupFilter) {
    const { type, action, value } = json
    this.type.value = type
    this.action.value = action
    this.value.value = value
  }
}
