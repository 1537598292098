import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Items } from 'saas/page/group/preview/Items'
import { Pages } from 'saas/page/group/preview/Pages'
import { Search } from 'saas/page/group/preview/Search'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

export const ItemsView = observer(() => {
  const { busy, count } = groupPreviewSaas.it

  return <Card gap={24} pad={24} minWidth={400} vertical>
    <Space gap={16} opposite>
      <Space gap={8}>
        <Typo size={16} semi>{i18n('group.Items')}</Typo>
        {busy ? <Spinner size={16} /> : count ? <Typo size={14} secondary>{count}</Typo> : undefined}
      </Space>
      <Search />
    </Space>
    <Items />
    <Pages />
  </Card>
})
