// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemTag-tag-2cfb{position:relative;display:flex;align-items:center;justify-content:center;margin:0;font-size:14px;background-color:var(--ui-background-color-soft);overflow:hidden;height:24px;min-width:24px;border-width:0;border-radius:4px}.ItemTag-content-2cfb{overflow:hidden;text-overflow:ellipsis}.ItemTag-close-2cfb{position:absolute;top:0;right:0;bottom:0;width:24px;margin:auto 0;display:flex;align-items:center;justify-content:center;transition:opacity 200ms ease-in-out;opacity:0;cursor:pointer;border-radius:4px}.ItemTag-icon-2cfb{background-color:var(--ui-background-color);border-radius:3px}.ItemTag-tag-2cfb:hover .ItemTag-close-2cfb{opacity:1}`, "",{"version":3,"sources":["webpack://./src/component/Tag/ItemTag.module.scss"],"names":[],"mappings":"AAAA,kBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,cAAA,CACA,gDAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,cAAA,CACA,iBAAA,CAGF,sBACE,eAAA,CACA,sBAAA,CAGF,oBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,oCAAA,CACA,SAAA,CACA,cAAA,CACA,iBAAA,CAGF,mBACE,2CAAA,CACA,iBAAA,CAGF,4CACE,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `ItemTag-tag-2cfb`,
	"content": `ItemTag-content-2cfb`,
	"close": `ItemTag-close-2cfb`,
	"icon": `ItemTag-icon-2cfb`
};
module.exports = ___CSS_LOADER_EXPORT___;
