import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { BackgroundColorInput } from 'saas/page/product/tryon/SettingsCard/BackgroundColorInput'
import { PrimaryColorInput } from 'saas/page/product/tryon/SettingsCard/PrimaryColorInput'
import { TextColorInput } from 'saas/page/product/tryon/SettingsCard/TextColorInput'
import { ThemeSelect } from 'saas/page/product/tryon/SettingsCard/ThemeSelect'

export const ColorsTab = observer(() => {
  return <Vertical>
    <Typo size={14} bold>{i18n('widget.Theme')}</Typo>
    <Gap height={8} />
    <ThemeSelect />
    <Gap height={20} />
    <Typo size={14} bold>{i18n('widget.Colors')}</Typo>
    <Gap height={12} />
    <PrimaryColorInput />
    <Gap height={16} />
    <TextColorInput />
    <Gap height={16} />
    <BackgroundColorInput />
  </Vertical>
})
