import { security } from 'app/controller/security'
import { notice } from 'app/notice'
import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { UserPicture } from 'section/Head/UserPicture'
import { me } from 'store/me'

export const ProfileModal = observer(() => {
  const { form, name } = modal.profileModal.it

  const onCancel = useLatestCallback(() => {
    modal.profileModal.close()
  })

  const onLogout = useLatestCallback(async () => {
    security.logout()
  })

  const onSave = useLatestCallback(async () => {
    if (!form.check()) return
    notice.success(i18n('message.Saved'))
  })

  return <Modal onCancel={onCancel}>
    <Space opposite gap={16}>
      <Space gap={12}>
        <UserPicture size={48} picture={me.user.picture} />
        <Vertical gap={2}>
          <Typo size={16} bold>
            {i18n('label.YourProfile')}
          </Typo>
          <Typo size={12}>
            {me.user.email}
          </Typo>
        </Vertical>
      </Space>
    </Space>
    <Vertical gap={12}>
      <TextField field={name} label={i18n('label.Name')} />
    </Vertical>
    <Space gap={12} opposite>
      <Button link compact icon={<Icon name="logout" />} action={onLogout}>
        {i18n('label.Logout')}
      </Button>
      <Space gap={12}>
        <Button action={onCancel}>{i18n('common.Cancel')}</Button>
        <Button primary disabled action={onSave}>{i18n('common.Save')}</Button>
      </Space>
    </Space>
  </Modal>
})
