import { makeAutoObservable } from 'mobx'

export class BooleanStore {
  private _value: boolean

  constructor(value: boolean = false) {
    this._value = value
    makeAutoObservable(this)
  }

  get value(): boolean {
    return this._value
  }

  set value(value: boolean) {
    this._value = value
  }

  get empty(): boolean {
    return !this._value
  }
}
