import { Input } from 'component/Input'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const TextsTab = observer(() => {
  const { title, description, agreement, thanks } = tryon.it.edit

  const onChangeTitle = useLatestCallback((value: string) => {
    tryon.it.edit.title = value.trim() || undefined
  })
  const onChangeDescription = useLatestCallback((value: string) => {
    tryon.it.edit.description = value.trim() || undefined
  })
  const onChangeAgreement = useLatestCallback((value: string) => {
    tryon.it.edit.agreement = value.trim() || undefined
  })
  const onChangeThanks = useLatestCallback((value: string) => {
    tryon.it.edit.thanks = value.trim() || undefined
  })

  return <Vertical>
    <Typo size={14} bold>{i18n('widget.EmailsCollection')}</Typo>
    <Gap height={16} />
    <Typo size={12} secondary>{i18n('widget.DefaultEmailLabel')}</Typo>
    <Gap height={4} />
    <Input value={title || ''} onChange={onChangeTitle} />
    <Gap height={12} />
    <Typo size={12} secondary>{i18n('widget.DefaultEmailPlaceholder')}</Typo>
    <Gap height={4} />
    <Input value={description || ''} onChange={onChangeDescription} />
    <Gap height={12} />
    <Typo size={12} secondary>{i18n('widget.DefaultSendEmailAgreement')}</Typo>
    <Gap height={4} />
    <Input value={agreement || ''} onChange={onChangeAgreement} />
    <Gap height={12} />
    <Typo size={12} secondary>{i18n('widget.DefaultSendEmailThanks')}</Typo>
    <Gap height={4} />
    <Input value={thanks || ''} onChange={onChangeThanks} />
  </Vertical>
})
