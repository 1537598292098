import { Select as SelectAntd } from 'antd'
import { SelectProps } from 'antd/es/select'
import { clsx } from 'clsx'
import { renderSelectTag } from 'component/Select/SelectTag'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import type { BaseSelectRef } from 'rc-select/lib/BaseSelect'
import { ReactElement, ReactNode, useRef, useState } from 'react'
import css from './Select.module.scss'

export interface Option<Data = void, Value = string> {
  label?: ReactNode
  value?: Value
  disabled?: boolean
  className?: string
  data?: Data
}

type Props<V, D, O extends Option<D, V>> = Omit<SelectProps<V, O>, 'onChange'> & {
  hover?: boolean
  error?: boolean
  onChange?: ((value: V, option: Option<D, V>) => void)
  wide?: boolean
}

export function Select<V, D, O extends Option<D, V>>(_props: Props<V, D, O>) {
  let { className, popupClassName, value, status, hover, error, wide, onChange, ...props } = _props
  const [open, setOpen] = useState('')
  const timeout = useRef<TimeoutId>()

  className = clsx(className, css.select, wide ? undefined : css.fit)
  popupClassName = clsx(popupClassName, css.popup)
  value = value || undefined
  status = status ?? error ? 'error' : undefined

  const clearIcon = <Icon name="close" size={16} />
  const allowClear = props.allowClear ?? { clearIcon }
  const suffixIcon = <Icon name="keyboard_arrow_down" size={16} />

  const scheduleClose = useLatestCallback(() => {
    clearTimeout(timeout.current)
    if (open === 'hover') {
      timeout.current = setTimeout(() => {
        if (open === 'hover') setOpen('')
      }, 100)
    }
  })

  const onMouseEnterMenu = useLatestCallback(() => {
    clearTimeout(timeout.current)
  })

  const onMouseLeaveMenu = useLatestCallback(() => {
    scheduleClose()
  })

  const onMenuRef = useLatestCallback((element: HTMLDivElement | null) => {
    const menu = element?.parentElement?.parentElement
    if (!menu) return
    menu.addEventListener('mouseenter', onMouseEnterMenu)
    menu.addEventListener('mouseleave', onMouseLeaveMenu)
  })

  const dropdownRender = useLatestCallback((menu: ReactElement) => {
    return <div ref={onMenuRef}>
      {menu}
    </div>
  })

  const onMouseEnterSelect = useLatestCallback(() => {
    clearTimeout(timeout.current)
    if (open === '') setOpen('hover')
  })

  const onMouseLeaveSelect = useLatestCallback(() => {
    scheduleClose()
  })

  const onRef = useLatestCallback((ref: BaseSelectRef | null) => {
    const select = ref?.nativeElement
    if (!hover || !select) return
    select.addEventListener('mouseenter', onMouseEnterSelect)
    select.addEventListener('mouseleave', onMouseLeaveSelect)
  })

  const onDropdownVisibleChange = useLatestCallback((open: boolean) => {
    setOpen(open ? 'open' : '')
  })

  return <SelectAntd
    ref={onRef}
    allowClear={allowClear}
    suffixIcon={suffixIcon}
    tagRender={renderSelectTag}
    notFoundContent={null}
    {...props}
    open={!!open}
    onChange={onChange as never}
    onDropdownVisibleChange={onDropdownVisibleChange}
    status={status}
    value={value}
    className={className}
    popupClassName={popupClassName}
    dropdownRender={dropdownRender}
  />
}
