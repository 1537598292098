import IconPlus from 'asset/icon-plus.svg'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { FilterButton } from 'page/demo-catalog/FilterButton/FilterButton'
import { GenderButton } from 'page/demo-catalog/GenderButton/GenderButton'
import { SortButton } from 'page/demo-catalog/SortButton/SortButton'
import { demo } from 'page/demo-catalog/store/DemoCatalogStore'
import { useState } from 'react'
import css from './DemoCatalogFilters.module.scss'

export const DemoCatalogFilters = observer(() => {
  const { options, form } = demo
  const { brandFilter, styleFilter, genderFilter, priceSorting } = demo.product

  const [brandsOpened, setBrandsOpened] = useState(false)
  const [stylesOpened, setStylesOpened] = useState(false)

  const onChangeBrands = useLatestCallback((value: string[]) => form.brands = value)
  const onChangeStyles = useLatestCallback((value: string[]) => form.styles = value)

  return <div className={css.wrapper}>
    <Space className={css.filters} gap={8}>
      {brandFilter && options.brands.length > 1 && <FilterButton
        title={i18n('demo.Brands')}
        value={form.brands}
        list={options.brands}
        onChange={onChangeBrands}
        onOpened={setBrandsOpened}
      >
        <span>{i18n('demo.Brand')}</span>
        {!form.brands.length && !brandsOpened ? <IconPlus /> :
          <span className={css.buttonCount}>{form.brands.length}</span>}
      </FilterButton>}
      {styleFilter && options.styles.length > 1 && <FilterButton
        title={i18n('demo.Styles')}
        value={form.styles}
        list={options.styles}
        onChange={onChangeStyles}
        onOpened={setStylesOpened}
      >
        <span>{i18n('demo.Style')}</span>
        {!form.styles.length && !stylesOpened ? <IconPlus /> :
          <span className={css.buttonCount}>{form.styles.length}</span>}
      </FilterButton>}
    </Space>
    {genderFilter && options.genders.length > 1 && <Space className={css.gender}>
      <div className={css.inner}>
        <GenderButton gender="" />
        {options.genders.map(gender => <GenderButton key={gender} gender={gender} />)}
      </div>
    </Space>}
    <Space className={css.price} gap={8} top>
      {priceSorting && <SortButton />}
    </Space>
  </div>
})
