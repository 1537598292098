import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { KeyboardEvent } from 'react'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'
import { getShortcut } from 'util/keyboard'

export const Search = observer(() => {
  const { search } = groupPreviewSaas.it

  const onChange = useLatestCallback((value: string) => {
    groupPreviewSaas.it.search = value
  })

  const onKeyDown = useLatestCallback((event: KeyboardEvent) => {
    const shortcut = getShortcut(event)
    if (shortcut === 'Enter') {
      groupPreviewSaas.it.refresh()
    }
  })

  return <Input value={search} width={200} placeholder={i18n('label.Search')}
    onChange={onChange} onKeyDown={onKeyDown} />
})
