// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Items-list-1828{transition:opacity 300ms ease-in-out}.Items-busy-1828{opacity:.3;transition:opacity 1s ease-in-out}`, "",{"version":3,"sources":["webpack://./src/saas/page/group/preview/Items.module.scss"],"names":[],"mappings":"AAAA,iBACE,oCAAA,CAGF,iBACE,UAAA,CACA,iCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `Items-list-1828`,
	"busy": `Items-busy-1828`
};
module.exports = ___CSS_LOADER_EXPORT___;
