import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ProductsList } from 'saas/page/products/components/ProductsList'
import { Title } from 'saas/page/products/components/Title'
import { productsSaas } from 'saas/store/products/ProductsSaasController'

export const SaasProductsView = observer(() => {
  const { products } = productsSaas.it
  const empty = !products.length

  return <Vertical gap={24}>
    <Title />
    {empty && <Typo size={12} secondary>
      {i18n('product.NoProducts')}
    </Typo>}
    {!empty && <ProductsList />}
  </Vertical>
})
