import { Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { filterConfigs } from 'saas/store/groups/GroupFilterConfigsCache'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { getFilterActionLabel } from 'type/ItemGroupFilter'

type FilterRowProps = {
  filter: GroupFilterStore
}

export const ActionSelect = observer(({ filter }: FilterRowProps) => {
  const { value, error, onChange } = filter.action
  const values = filterConfigs.it.actions(filter.type.value)
  const options = values.map(value => ({ value, label: getFilterActionLabel(value) }))
  return <Space width={160}>
    <Select wide value={value} error={error} options={options} onChange={onChange} />
  </Space>
})
