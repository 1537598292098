import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { observer } from 'mobx-react-lite'
import { getFilterActionLabel, getFilterTypeLabel, ItemGroupFilter } from 'type/ItemGroupFilter'

type Props = {
  filter: ItemGroupFilter
}

export const FilterText = observer(({ filter }: Props) => {
  const { type, action, value } = filter

  return <Space gap={4}>
    <Typo size={12}>{getFilterTypeLabel(type)}</Typo>
    <Typo size={12} secondary>{getFilterActionLabel(action)}</Typo>
    <Typo size={12}>{value}</Typo>
  </Space>
})
