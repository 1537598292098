import { i18n } from 'i18n'
import { capitalize } from 'lodash'

export const reviewStatusValues = ['wait', 'review', 'retry', 'public', 'cancel'] as const

export type ExternalStatus = typeof reviewStatusValues[number]

export function fixReviewStatus(status: ExternalStatus | string | null | undefined): ExternalStatus | undefined {
  if (!status) return undefined
  if (['null', 'none', 'not set'].includes(status)) return undefined
  return status as ExternalStatus
}

export function getReviewStatusLabel(status: ExternalStatus | '' | null | undefined): string {
  status = fixReviewStatus(status)
  if (!status) return i18n('review.None')
  switch (status) {
    case 'wait':
      return i18n('review.Waiting')
    case 'review':
      return i18n('review.OnReview')
    case 'retry':
      return i18n('review.Retry')
    case 'public':
      return i18n('review.Public')
    case 'cancel':
      return i18n('review.Canceled')
    default:
      return capitalize(status).replace(/[_-]/g, ' ')
  }
}

export const buildExternalStatusOptions = (admin: boolean) => reviewStatusValues.map(status => ({
  value: status,
  label: getReviewStatusLabel(status),
  disabled: !admin && ['wait', 'review'].includes(status),
}))
