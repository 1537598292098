import { observer } from 'mobx-react-lite'
import { saasGroupPageController } from 'saas/page/group/SaasGroupPageController'
import { SaasGroupView } from 'saas/page/group/SaasGroupView'
import { Page } from 'saas/screen/Page'
import { usePageController } from 'store/base/page/usePageController'
import { route } from 'store/route'

export const SaasGroupPage = observer(() => {
  const company_id = route.unit(1)
  const group_id = route.is('/company/*/group/*') ? route.unit(3) : undefined

  const stub = usePageController(saasGroupPageController, { company_id, group_id })
  if (stub) return stub

  return <Page>
    <SaasGroupView />
  </Page>
})
