import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ActionSelect } from 'saas/page/group/components/FilterRow/ActionSelect'
import { TypeSelect } from 'saas/page/group/components/FilterRow/TypeSelect'
import { ValueInput } from 'saas/page/group/components/FilterRow/ValueInput'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'

type FilterRowProps = {
  filter: GroupFilterStore
}

export const FilterRow = observer(({ filter }: FilterRowProps) => {
  const onDelete = useLatestCallback(() => {
    groupFormSaas.it.filters.data.remove(filter)
  })

  return <Space gap={8}>
    <TypeSelect filter={filter} />
    <ActionSelect filter={filter} />
    <ValueInput filter={filter} />
    <IconButton action={onDelete}>
      <Icon name="delete" />
    </IconButton>
  </Space>
})
