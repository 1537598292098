import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'

export const Description = observer(() => {
  const { description } = groupFormSaas.it

  return <TextField field={description} rows={4} label={i18n('label.Description')} />
})
