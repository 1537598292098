import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Limit } from 'saas/page/group/preview/Limit'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

export const Pages = observer(() => {
  const store = groupPreviewSaas.it
  const { pages } = store

  const page = store.page + 1
  const start = page <= 1
  const end = page >= pages
  const none = start && end

  const onLeft = useLatestCallback(async () => {
    store.page -= 1
  })

  const onRight = useLatestCallback(async () => {
    store.page += 1
  })

  return <Space opposite>
    {none ? <div /> : <Space>
      <IconButton disabled={start} action={onLeft}>
        <Icon name="keyboard_arrow_left" size={16} />
      </IconButton>
      <Space gap={4} center>
        <Space minWidth={20} right>
          <Typo size={14}>{page}</Typo>
        </Space>
        <Typo size={14} tertiary>/</Typo>
        <Space minWidth={20}>
          <Typo size={14} tertiary>{pages}</Typo>
        </Space>
      </Space>
      <IconButton disabled={end} action={onRight}>
        <Icon name="keyboard_arrow_right" size={16} />
      </IconButton>
    </Space>}
    <Limit />
  </Space>
})
