// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Typo-medium-71cd{font-weight:500}.Typo-semi-71cd{font-weight:600}.Typo-bold-71cd{font-weight:700}.Typo-mono-71cd{font-family:var(--ui-font-family-monospace),monospace}.Typo-center-71cd{text-align:center}.Typo-ellipsis-71cd{display:block;flex-shrink:1;min-width:0;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.Typo-one-71cd{line-height:1}.Typo-nowrap-71cd{white-space:nowrap}.Typo-wrap-71cd{white-space:wrap;word-break:break-all}.Typo-balance-71cd{white-space:wrap;text-wrap:balance;word-break:break-all}.Typo-upper-71cd{text-transform:uppercase}.Typo-code-71cd{white-space:pre}.Typo-pre-71cd{white-space:pre-wrap}.Typo-primary-71cd{color:var(--ui-primary-color)}.Typo-first-71cd{color:var(--ui-text-color)}.Typo-secondary-71cd{color:var(--ui-text-color-secondary)}.Typo-tertiary-71cd{color:var(--ui-text-color-tertiary)}.Typo-error-71cd{color:var(--ui-text-color-error)}.Typo-size10-71cd{font-size:10px}.Typo-size11-71cd{font-size:11px}.Typo-size12-71cd{font-size:12px}.Typo-size13-71cd{font-size:13px}.Typo-size14-71cd{font-size:14px}.Typo-size16-71cd{font-size:16px}.Typo-size18-71cd{font-size:18px}.Typo-size20-71cd{font-size:20px}.Typo-size24-71cd{font-size:24px}`, "",{"version":3,"sources":["webpack://./src/component/Typo/Typo.module.scss"],"names":[],"mappings":"AAAA,kBACE,eAAA,CAGF,gBACE,eAAA,CAGF,gBACE,eAAA,CAGF,gBACE,qDAAA,CAGF,kBACE,iBAAA,CAGF,oBACE,aAAA,CACA,aAAA,CACA,WAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CAGF,eACE,aAAA,CAGF,kBACE,kBAAA,CAGF,gBACE,gBAAA,CACA,oBAAA,CAGF,mBACE,gBAAA,CACA,iBAAA,CACA,oBAAA,CAGF,iBACE,wBAAA,CAGF,gBACE,eAAA,CAGF,eACE,oBAAA,CAGF,mBACE,6BAAA,CAGF,iBACE,0BAAA,CAGF,qBACE,oCAAA,CAGF,oBACE,mCAAA,CAGF,iBACE,gCAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA,CAGF,kBACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"medium": `Typo-medium-71cd`,
	"semi": `Typo-semi-71cd`,
	"bold": `Typo-bold-71cd`,
	"mono": `Typo-mono-71cd`,
	"center": `Typo-center-71cd`,
	"ellipsis": `Typo-ellipsis-71cd`,
	"one": `Typo-one-71cd`,
	"nowrap": `Typo-nowrap-71cd`,
	"wrap": `Typo-wrap-71cd`,
	"balance": `Typo-balance-71cd`,
	"upper": `Typo-upper-71cd`,
	"code": `Typo-code-71cd`,
	"pre": `Typo-pre-71cd`,
	"primary": `Typo-primary-71cd`,
	"first": `Typo-first-71cd`,
	"secondary": `Typo-secondary-71cd`,
	"tertiary": `Typo-tertiary-71cd`,
	"error": `Typo-error-71cd`,
	"size10": `Typo-size10-71cd`,
	"size11": `Typo-size11-71cd`,
	"size12": `Typo-size12-71cd`,
	"size13": `Typo-size13-71cd`,
	"size14": `Typo-size14-71cd`,
	"size16": `Typo-size16-71cd`,
	"size18": `Typo-size18-71cd`,
	"size20": `Typo-size20-71cd`,
	"size24": `Typo-size24-71cd`
};
module.exports = ___CSS_LOADER_EXPORT___;
