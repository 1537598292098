import { notice } from 'app/notice'
import { pageStore } from 'feature/page/PageStore'
import { i18n } from 'i18n'
import { action, reaction } from 'mobx'
import { api } from 'store/api'
import { LazyStore } from 'store/base/LazyStore'
import { mx } from 'store/base/mx'
import { CollectionListStore, CollectionStore } from 'store/collection'
import { CompaniesStore } from 'store/company/CompaniesStore'
import { CompanyStore } from 'store/company/CompanyStore'
import { home } from 'store/home/index'
import { ui } from 'store/home/ui/HomeUiStore'
import { formatManagerRoute, ManagerRoute } from 'store/manager/routes'
import { ProductFiltersStore, ProductStore } from 'store/product'
import { ProductItem } from 'store/product/ProductItem'
import { ProfileStore } from 'store/profile'
import { route } from 'store/route'
import { storage } from 'store/storage'
import { minAsync } from 'util/async'

function go(params: ManagerRoute) {
  const pathname = formatManagerRoute(params)
  if (route.pathname !== pathname) route.push(pathname)
}

export class HomeController {
  readonly ui = ui
  readonly companies = new CompaniesStore()
  readonly my_collections = new LazyStore<CollectionListStore>()
  readonly company = mx.ref<CompanyStore>()
  readonly collection = mx.ref<CollectionStore>()
  readonly productFilters = new ProductFiltersStore()
  readonly product = mx.ref<ProductStore>()
  readonly profile = new LazyStore<ProfileStore>()

  constructor() {
    this.watchCompanyId()
    this.watchCollectionId()
    this.watchProductId()
  }

  get company_id(): string | undefined {
    return home.company.optional?.company_id
  }

  get collections(): LazyStore<CollectionListStore> {
    return this.company.optional?.collections ?? this.my_collections
  }

  selectCompany(company_id: string) {
    const company = home.companies.companies.find(co => co.company_id === company_id)
    if (company) home.company.it = company
    return company
  }

  readonly start = minAsync(async () => {
    await pageStore.process(async () => {
      if (!ui.creator) throw new Error('start internal')
      await this.startCreator()
      pageStore.ready = true
    })
  })

  async openCompany(company: CompanyStore) {
    const companyId = company.company.company_id
    if (ui.saas) {
      route.push(`/company/${companyId}`)
    }
    if (ui.manager) {
      go({ page: 'company', companyId })
    }
    if (ui.creator) {
      this.product.close()
      this.collection.close()
      this.company.it = company
      await company.loadCollections()
    }
  }

  async openCollection(collection: CollectionStore) {
    const collectionId = collection.collectionId
    if (ui.saas) {
      route.push(`/collection/${collectionId}`)
    }
    if (ui.manager) {
      go({ page: 'collection', collectionId })
    }
    if (ui.creator) {
      this.product.close()
      this.collection.it = collection
      await collection.loadItems()
    }
  }

  closeCollection() {
    const collection = this.collection.optional
    if (!collection) return
    const companyId = collection.companyId
    if (ui.saas) {
      route.push(`/company/${companyId}`)
    }
    if (ui.manager) {
      go({ page: 'company', companyId })
    }
    if (ui.creator) {
      this.collection.close()
    }
  }

  async openProduct(product: ProductStore) {
    const productId = product.itemId
    if (ui.saas) {
      route.push(`/item/${productId}`)
    }
    if (ui.manager) {
      go({ page: 'product', productId })
    }
    if (ui.creator) {
      this.product.it = product
    }
  }

  closeProduct() {
    const product = this.product.optional
    if (!product) return
    const collectionId = product.collectionId
    if (ui.saas) {
      route.push(`/collection/${collectionId}`)
    }
    if (ui.manager) {
      go({ page: 'collection', collectionId })
    }
    if (ui.creator) {
      this.product.close()
    }
  }

  async deleteCollection(collection: CollectionStore) {
    const collectionId = collection.collectionId

    this.collections.optional?.deleteCollection(collection)
    if (this.collection.optional === collection) this.collection.close()
    if (this.product.optional?.collectionId === collectionId) this.product.close()

    await api.deleteCollection(collectionId)
    notice.success(i18n('collection.CollectionDeleted'))
  }

  async deleteProduct(item: ProductItem) {
    this.collection.optional?.deleteItem(item)
    if (this.product.optional === item.store.it) this.product.close()

    await api.deleteItem(item.store.it.itemId)
    notice.success(i18n('item.ItemDeleted'))
  }

  loadProfile() {
    return this.profile.open(async () => {
      const identities = await api.getIdentities()
      return new ProfileStore(identities)
    })
  }

  readonly goHome = action((): void => {
    this.collections.optional?.resetFilters()
    this.productFilters.resetFilters()
    if (ui.manager) {
      const companyId = home.company.optional?.company.company_id
      const link = formatManagerRoute({ page: 'company', companyId })
      route.push(link)
      return
    }
    this.product.close()
    this.collection.close()
  })

  private watchCompanyId(): void {
    reaction(() => this.company.optional?.company.company_id, id => storage.saveCompanyId(id))
  }

  private watchCollectionId(): void {
    reaction(() => this.collection.optional?.collectionId, id => storage.saveCollectionId(id))
  }

  private watchProductId(): void {
    reaction(() => this.product.optional?.itemId, id => storage.saveProductId(id))
  }

  private readonly startCreator = async () => {
    const collectionId = storage.loadCollectionId()
    const productId = storage.loadProductId()
    await this.my_collections.open(async () => {
      const response = await api.getMyCollections()
      return new CollectionListStore(response)
    })
    const collection = this.my_collections.it.findCollection(collectionId)
    if (collection) await this.openCollection(collection)
    const product = collection?.findStoredProduct(productId)
    if (product) await this.openProduct(product.store.it)
  }
}
