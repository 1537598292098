import { clsx } from 'clsx'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Item } from 'saas/page/group/preview/Item'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'
import css from './Items.module.scss'

export const Items = observer(() => {
  const { busy, visibleItems } = groupPreviewSaas.it

  return <Vertical gap={12} className={clsx(css.list, busy && css.busy)}>
    {!visibleItems.length && <Space>
      <Typo size={14} secondary>{i18n('item.NoItems')}</Typo>
    </Space>}
    {visibleItems.map(item => <Item key={item.item_id} item={item} />)}
  </Vertical>
})
