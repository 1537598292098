import { makeAutoObservable } from 'mobx'
import { extractFilterValues, GroupFilterConfig, GroupFilterValue } from 'type/GroupFilterConfig'
import { by } from 'util/sort'

class Config {
  readonly name: string
  readonly type: string
  readonly actions: string[]
  readonly values: GroupFilterValue[]

  constructor(json: GroupFilterConfig) {
    this.name = json.name
    this.type = json.type
    this.actions = json.actions.map(val => val.val)
    this.values = extractFilterValues(json)
  }
}

export class GroupFilterConfigsStore {
  private readonly configs: Config[]

  constructor(jsons: GroupFilterConfig[]) {
    makeAutoObservable<this, 'configs'>(this, { configs: false })
    this.configs = jsons.map(json => new Config(json)).sort(by(config => config.name.toLowerCase()))
  }

  get names(): string[] {
    return this.configs.map(f => f.name)
  }

  config(type: string | undefined): Config | undefined {
    return type ? this.byName.get(type) : undefined
  }

  actions(type: string | undefined): string[] {
    return this.config(type)?.actions ?? []
  }

  values(type: string | undefined): GroupFilterValue[] {
    return this.config(type)?.values ?? []
  }

  private get byName(): Map<string, Config> {
    const map = new Map<string, Config>()
    for (const filter of this.configs) {
      map.set(filter.name, filter)
    }
    return map
  }
}
