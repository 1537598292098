import { groupDeleteModal } from 'saas/store/groups/GroupDeleteModalStore'
import { filterConfigs } from 'saas/store/groups/GroupFilterConfigsCache'
import { groupFormSaas, GroupFormStore } from 'saas/store/groups/GroupFormStore'
import { groupPreviewSaas, GroupPreviewStore } from 'saas/store/groups/GroupPreviewStore'
import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { groupSaas, GroupStore } from 'saas/store/groups/GroupStore'
import { PageController } from 'store/base/page/PageController'
import { home } from 'store/home'

type Params = {
  company_id: string
  group_id: string
}

type Data = {
  group: GroupStore
}

export class SaasGroupPageController implements PageController<Params, Data> {
  async load({ company_id, group_id }: Params): Promise<Data | undefined> {
    const company = home.selectCompany(company_id)
    if (!company) return
    const _groups = groupsSaas.load(company_id).load()
    const _configs = filterConfigs.load(company_id).reload()
    await _configs
    const groups = await _groups
    if (!groups) return
    const group = groups.groups.find(group => group.group_id === group_id)
    if (!group) return
    return { group }
  }

  select({ company_id }: Params, { group }: Data): void {
    groupsSaas.select(company_id)
    filterConfigs.select(company_id)
    groupSaas.open(group)
    groupFormSaas.open(new GroupFormStore(group))
    groupPreviewSaas.open(new GroupPreviewStore(group))
  }

  close(): void {
    groupDeleteModal.close()
    groupPreviewSaas.close()
    groupFormSaas.close()
    groupSaas.close()
    filterConfigs.close()
    groupsSaas.close()
  }
}

export const saasGroupPageController: PageController<Params, Data> = new SaasGroupPageController()
