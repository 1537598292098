import { i18n } from 'i18n'

export type FilterConditionType = 'all' | 'any'
export type FilterSortType = 'manual' | 'none'

export const filterNames = [
  'vendor',
  'product',
  'prompt',
  'internal_status',
  'external_status',
  'gender',
  'dress_types',
  'dress_seasons',
  'dress_styles',
  'item_tags',
  'collection',
] as const

export type FilterNameKnown = (typeof filterNames)[number]
export type FilterName = FilterNameKnown | string

export const filterActions = [
  'equals',
  'not_equals',
  'includes',
  'not_includes',
]

export type FilterActionKnown = (typeof filterActions)[number]
export type FilterAction = FilterActionKnown | string

export type ItemGroupFilter = {
  type: FilterName
  action: FilterAction
  value: string
}

export function getFilterTypeLabel(type: FilterName): string {
  if (!type) return i18n('group.filter.type.None')
  switch (type) {
    case 'vendor':
      return i18n('group.filter.type.Vendor')
    case 'product':
      return i18n('group.filter.type.Product')
    case 'prompt':
      return i18n('group.filter.type.Prompt')
    case 'internal_status':
      return i18n('group.filter.type.InternalStatus')
    case 'external_status':
      return i18n('group.filter.type.ExternalStatus')
    case 'gender':
      return i18n('group.filter.type.Gender')
    case 'dress_types':
      return i18n('group.filter.type.DressTypes')
    case 'dress_seasons':
      return i18n('group.filter.type.DressSeasons')
    case 'dress_styles':
      return i18n('group.filter.type.DressStyles')
    case 'item_tags':
      return i18n('group.filter.type.ItemTags')
    case 'collection':
      return i18n('group.filter.type.Collection')
    default:
      return (type as string).toUpperCase()
  }
}

export function getFilterActionLabel(action: FilterAction): string {
  if (!action) return i18n('group.filter.action.None')
  switch (action) {
    case 'equals':
      return i18n('group.filter.action.Equals')
    case 'not_equals':
      return i18n('group.filter.action.NotEquals')
    case 'includes':
      return i18n('group.filter.action.Includes')
    case 'not_includes':
      return i18n('group.filter.action.NotIncludes')
    default:
      return (action as string).toUpperCase()
  }
}
