import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { api } from 'store/api'
import { ImageFile } from 'store/base/ImageFile'
import { me } from 'store/me'
import { ProductStore } from 'store/product/ProductStore'
import { Item, UpdateItemRequest } from 'type/Item'

export class SaveProductProcess {
  private readonly update: UpdateItemRequest
  private readonly remove: readonly string[]
  private readonly upload: readonly ImageFile[]
  private removeErrors = 0
  private uploadErrors = 0

  constructor(store: ProductStore) {
    this.update = this.buildUpdate(store)
    this.remove = this.assembleRemove(store)
    this.upload = this.assembleUpload(store)
  }

  async process(): Promise<Item> {
    const { item_id } = this.update

    const promises = [
      ...this.remove.map(imageId => this.removeImage(imageId)),
      ...this.upload.map(file => this.uploadImage(item_id, file)),
    ]

    await Promise.allSettled(promises)

    if (this.removeErrors) notice.error(i18n('item.FailedToDeleteCountImages', { count: this.removeErrors }))
    if (this.uploadErrors) notice.error(i18n('item.FailedToUploadCountImages', { count: this.uploadErrors }))

    return await api.updateItem(this.update)
  }

  private buildUpdate(store: ProductStore): UpdateItemRequest {
    const form = store.form
    const user_id = me.user.user_id
    const collection_id = store.collectionId
    const item_id = store.itemId
    return {
      user_id,
      collection_id,
      item_id,
      product_name: form.product_name.value,
      brand_name: form.brand_name.value,
      article: form.article.value,
      external_item_id: form.sku.value,
      item_descr: form.description.value,
      prompt: form.default_prompt.value,
      environment: form.background_prompt.value,
      links_name: form.button_name.value,
      links: form.button_url.value,
      dress_styles: form.styles.value.join(','),
      dress_types: form.types.value.join(','),
      dress_seasons: form.seasons.value.join(','),
      dress_gender: form.gender.value.join(','),
      internal_status: form.status.value ?? '',
      external_status: form.review.value ?? '',
    }
  }

  private assembleRemove(store: ProductStore): readonly string[] {
    const old = new Map(store.data.assets.map(image => [image.url, image]))
    const images = store.form.images.value.map(image => image.url)
    for (const image of images) {
      old.delete(image)
    }
    return Array.from(old.values()).map(asset => asset.imageId)
  }

  private assembleUpload(store: ProductStore): readonly ImageFile[] {
    return store.form.images.value.filter(image => !!image.file)
  }

  private async removeImage(imageId: string): Promise<void> {
    try {
      await api.deleteItemImage(imageId)
    } catch (e) {
      console.error('remove image failed', e)
      ++this.removeErrors
    }
  }

  private async uploadImage(item_id: string, image: ImageFile): Promise<void> {
    if (!image.file) return
    try {
      const response = await api.uploadItemImage(item_id, image.file)
      image.update(response.url)
    } catch (e) {
      console.error('upload image failed', e)
      ++this.uploadErrors
    }
  }
}
