import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { GroupDeleteModal } from 'saas/page/group/components/GroupDeleteModal'
import { MainView } from 'saas/page/group/components/MainView'
import { Title } from 'saas/page/group/components/Title'
import { ItemsView } from 'saas/page/group/preview/ItemsView'
import { groupDeleteModal } from 'saas/store/groups/GroupDeleteModalStore'

export const SaasGroupView = observer(() => {
  return <Vertical gap={24}>
    <Title />
    <MainView />
    <ItemsView />
    {groupDeleteModal.present && <GroupDeleteModal />}
  </Vertical>
})
