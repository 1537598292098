import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { View } from 'saas/page/product/common/View/View'
import { Details } from 'saas/page/product/tryon/Details/Details'
import { Preview } from 'saas/page/product/tryon/Preview/Preview'
import { SettingsCard } from 'saas/page/product/tryon/SettingsCard/SettingsCard'

export const TryOnView = observer(() => {
  return <View>
    <Space gap={16} parallel>
      <SettingsCard />
      <Preview />
    </Space>
    <Details />
  </View>
})
