import { clsx } from 'clsx'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'
import css from './StatusSwitch.module.scss'

export const StatusSwitch = observer(() => {
  const { busy, json } = stand.it
  const { published } = json

  const onPublish = useLatestCallback(() => stand.it.publish(true))
  const onUnpublish = useLatestCallback(() => stand.it.publish(false))

  return <Vertical>
    <Typo size={14}>{i18n('product.PublishStatus')}</Typo>
    <Gap height={4} />
    <Space>
      <Button className={clsx(css.button, published && css.active)}
        primary={published} action={onPublish} disabled={busy}>
        {i18n('product.Published')}
      </Button>
      <Button className={clsx(css.button, !published && css.active)}
        error={!published} action={onUnpublish} disabled={busy}>
        {i18n('product.NotPublished')}
      </Button>
    </Space>
  </Vertical>
})
