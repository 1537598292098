import { Button } from 'component/Button'
import { IconButton } from 'component/Button/IconButton'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { groupDeleteModal, GroupDeleteModalStore } from 'saas/store/groups/GroupDeleteModalStore'
import { groupSaas } from 'saas/store/groups/GroupStore'

export const Title = observer(() => {
  const { company_id } = groupSaas.it
  const back = `/company/${company_id}/groups`

  const onDelete = useLatestCallback(() => {
    groupDeleteModal.open(new GroupDeleteModalStore(groupSaas.it))
  })

  return <Space gap={18} opposite>
    <Space gap={8}>
      {<Link path={back}>
        <IconButton small>
          <Icon name="arrow_back" />
        </IconButton>
      </Link>}
      <Typo size={18} bold>
        {i18n('group.Group')}
      </Typo>
    </Space>
    <Button error action={onDelete}>
      {i18n('group.DeleteGroup')}
    </Button>
  </Space>
})
