import { i18n } from 'i18n'

export const genders = [
  'female',
  'male',
  'unisex',
] as const

export type GenderKnown = (typeof genders)[number]
export type Gender = GenderKnown | string

export function getGenderLabel(gender: Gender): string {
  if (!gender) return i18n('label.None')
  switch (gender) {
    case 'female':
      return i18n('label.Female')
    case 'male':
      return i18n('label.Male')
    case 'unisex':
      return i18n('label.Unisex')
    default:
      return gender.toUpperCase()
  }
}
