import { Link } from 'component/Link'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { FilterText } from 'saas/page/groups/components/FilterText'
import { GroupStore } from 'saas/store/groups/GroupStore'

type Props = {
  group: GroupStore
}

export const GroupRow = observer(({ group }: Props) => {
  const { company_id, group_id, group_name, filter } = group.json
  const path = `/company/${company_id}/group/${group_id}`

  return <tr>
    <td>
      <Link path={path}>
        <Typo size={14}>{group_name}</Typo>
      </Link>
    </td>
    <td>
      <Vertical gap={4}>
        {filter.filters.map((filter, index) => <FilterText key={index} filter={filter} />)}
      </Vertical>
    </td>
  </tr>
})
