import { makeAutoObservable } from 'mobx'

export class StringTypedStore<Value extends string> {
  private _value: Value | ''

  constructor(value?: Value) {
    this._value = value! ?? ''
    makeAutoObservable(this)
  }

  get value(): Value | '' {
    return this._value
  }

  set value(value: Value | '') {
    this._value = value ?? ''
  }

  get empty(): boolean {
    return !this._value.length
  }

  readonly onChange = (value: Value | '' | null | undefined) => {
    this._value = value! ?? ''
  }
}
