import { i18n } from 'i18n'
import { makeAutoObservable, runInAction } from 'mobx'
import { GroupStore } from 'saas/store/groups/GroupStore'
import { api } from 'store/api'
import { SerialProcessStore } from 'store/base/process/SerialProcessStore'
import { me } from 'store/me'
import { route } from 'store/route'
import { CreateItemGroupRequest, ItemGroup, ItemGroupFilterConfig } from 'type/ItemGroup'
import { matchSearch } from 'util/search'

export class GroupsStore {
  private readonly serial = new SerialProcessStore()
  readonly company_id: string
  groups: GroupStore[] = []
  private _filter = ''

  constructor(company_id: string, groups: ItemGroup[]) {
    makeAutoObservable(this)
    this.company_id = company_id
    this.groups = groups.map(group => new GroupStore(group))
  }

  get busy(): boolean {
    return this.serial.busy
  }

  get filter(): string {
    return this._filter
  }

  set filter(value: string) {
    this._filter = value
  }

  get filteredGroups(): GroupStore[] {
    return this.groups.filter(({ json }) => matchSearch(this.filter, [json.group_name]))
  }

  async createGroup() {
    const { user_id } = me.user
    const { company_id } = this
    const group_name = i18n('group.Group')
    const filter: ItemGroupFilterConfig = { conditionType: 'any', filters: [] }
    const request: CreateItemGroupRequest = { user_id, company_id, group_name, filter }
    const json = await this.serial.call(() => api.createGroup(request))
    const { group_id } = json
    runInAction(() => this.groups.push(new GroupStore(json)))
    route.push(`/company/${company_id}/group/${group_id}`)
  }

  removeGroup(group: GroupStore) {
    const { company_id } = this
    const index = this.groups.indexOf(group)
    if (index < 0) return
    this.groups.splice(index, 1)
    route.push(`/company/${company_id}/groups`)
    void this.serial.call(() => api.deleteGroup(group.group_id))
  }
}
