import { Upload as UploadAntd } from 'antd'
import { notice } from 'app/notice'
import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { DragEvent, ReactNode, useRef, useState } from 'react'
import { ASPECT_RATIO } from 'util/image'
import css from './Upload.module.scss'

type Accept = 'image' | 'icon' | 'csv'

const IMAGE_TYPES = ['image/jpeg', 'image/png']
const ICON_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml']
const CSV_TYPES = ['text/csv']

export const MAX_IMAGE_SIZE_MB = 3
export const MAX_ICON_SIZE_MB = 1
export const MAX_CSV_SIZE_MB = 10

type Props = {
  accept?: Accept
  active?: boolean
  button?: boolean
  pic?: boolean
  children?: ReactNode
  error?: boolean
  disabled?: boolean
  height?: number | string
  multiple?: boolean
  onFile?(file: File): void | Promise<void>
  width?: number
}

function getAccept(accept: Accept | undefined): string | undefined {
  switch (accept) {
    case 'image':
      return IMAGE_TYPES.join(',')
    case 'icon':
      return ICON_TYPES.join(',')
    case 'csv':
      return CSV_TYPES.join(',')
    default:
      return undefined
  }
}

function checkImageFile(file: File): boolean {
  if (!IMAGE_TYPES.includes(file.type)) {
    notice.error(i18n('upload.OnlyJpegAndPngImagesAreAllowed'))
    return false
  }
  if (file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
    notice.error(i18n('upload.MaximumSizeIsCountMb', { count: MAX_IMAGE_SIZE_MB }))
    return false
  }
  return true
}

function checkIconFile(file: File): boolean {
  if (!ICON_TYPES.includes(file.type)) {
    notice.error(i18n('upload.OnlyJpegPngAndSvgImagesAreAllowed'))
    return false
  }
  if (file.size > MAX_ICON_SIZE_MB * 1024 * 1024) {
    notice.error(i18n('upload.MaximumSizeIsCountMb', { count: MAX_ICON_SIZE_MB }))
    return false
  }
  return true
}

function checkCsvFile(file: File): boolean {
  if (!CSV_TYPES.includes(file.type)) {
    notice.error(i18n('upload.OnlyCsvFileIsAllowed'))
    return false
  }
  if (file.size > MAX_CSV_SIZE_MB * 1024 * 1024) {
    notice.error(i18n('upload.MaximumSizeIsCountMb', { count: MAX_CSV_SIZE_MB }))
    return false
  }
  return true
}

function checkFile(accept: Accept | undefined, file: File): boolean {
  if (accept === 'image') return checkImageFile(file)
  if (accept === 'icon') return checkIconFile(file)
  if (accept === 'csv') return checkCsvFile(file)
  return true
}

export function Upload(props: Props) {
  let { height, width } = props

  const { button, pic, children, error, disabled, multiple, onFile } = props
  const [active, setActive] = useState(false)
  const dashed = !props.active
  const accept = getAccept(props.accept)
  const ref = useRef<HTMLDivElement>(null)

  height = pic && height == null && typeof width === 'number' ? width / ASPECT_RATIO : height
  width = pic && width == null && typeof height === 'number' ? height * ASPECT_RATIO : width

  const beforeUpload = useLatestCallback(async (file: File) => {
    if (onFile && checkFile(props.accept, file)) {
      await onFile(file)
    }
    return false
  })

  const onDragEnter = useLatestCallback((_event: DragEvent) => {
    setActive(true)
  })

  const onDragLeave = useLatestCallback((event: DragEvent<HTMLDivElement>) => {
    const target = ref.current
    if (!target) return
    const out = !target.contains(event.relatedTarget as Node)
    if (out) setActive(false)
  })

  return <div className={css.upload} ref={ref} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
    <UploadAntd disabled={disabled} accept={accept} fileList={[]} multiple={multiple} beforeUpload={beforeUpload}>
      {button ? (
        <Button>
          {children}
        </Button>
      ) : (
        <Button className={css.box} width={width} height={height} pad={0} dashed={dashed} active={active} error={error}>
          <Space center>{children}</Space>
        </Button>
      )}
    </UploadAntd>
  </div>
}
