import { notice } from 'app/notice'
import { DataStore, FieldOptions, FieldStore } from 'form/store/FieldStore'
import { i18n } from 'i18n'
import { makeAutoObservable } from 'mobx'

export class FormStore {
  private readonly fields: FieldStore[] = []

  constructor() {
    makeAutoObservable(this)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field<Data extends DataStore<any>, Value = Data['value']>
  (data: Data, options?: FieldOptions<Value>): FieldStore<Value, Data> {
    const field = new FieldStore<Value>(data, options)
    this.fields.push(field as FieldStore<unknown, Data>)
    return field as FieldStore<Value, Data>
  }

  get error(): boolean {
    return this.fields.some(field => field.error)
  }

  check(): boolean {
    this.fields.forEach(field => {
      field.check()
      if (field.focus) console.error('field in focus on check')
    })
    if (this.error) notice.error(i18n('form.IncompleteForm'))
    return !this.error
  }

  remove<V>(field: FieldStore<V, DataStore<V>>) {
    const index = this.fields.indexOf(field as FieldStore)
    if (index >= 0) this.fields.splice(index, 1)
  }
}
