import { notice } from 'app/notice'
import { rest } from 'app/rest'
import { me } from 'store/me'
import {
  ApiKey,
  CreateApiKeyRequest,
  CreateApiKeyResponse,
  UpdateApiKeyRequest,
  UpdateApiKeyResponse,
} from 'type/ApiKey'
import {
  Collection,
  CollectionItem,
  CollectionItems,
  CreateCollectionRequest,
  UpdateCollectionRequest,
} from 'type/Collection'
import {
  Comment,
  CommentsResponse,
  CreateCommentRequest,
  CreateLikeCommentRequest,
  CreateStatusCommentRequest,
  LikeState,
  UpdateCommentRequest,
} from 'type/Comment'
import { CreateCompanyRequest, SaasCompany, UpdateCompanyRequest } from 'type/Company'
import { CompanyConfig } from 'type/CompanyConfig'
import { DemoResponse, DemoWidget } from 'type/Demo'
import { DemoCatalogResponse, StandCatalogResponse } from 'type/DemoCatalog'
import { GroupFilterConfig } from 'type/GroupFilterConfig'
import { Identity } from 'type/Identity'
import { Image } from 'type/Image'
import { Inference, NewInference } from 'type/Inference'
import { CreateItemRequest, Item, UpdateItemRequest, UpdateItemStatusRequest, UploadItemsRequest } from 'type/Item'
import {
  CreateItemGroupRequest,
  GroupItemsRequest,
  GroupItemsResponse,
  ItemGroup,
  UpdateItemGroupRequest,
} from 'type/ItemGroup'
import { Member, MemberUpdate, UpdateMemberPermissionsRequest, UpdateMemberRequest } from 'type/Member'
import { Product } from 'type/product/Product'
import { ProductsConfig } from 'type/product/ProductsConfig'
import { SearchConfigResponse, SearchRequest, SearchResponse } from 'type/Search'
import {
  MasksSourceRequest,
  MasksSourceResponse,
  PromptSourceRequest,
  PromptSourceResponse,
  Source,
  UpdateSourceRequest,
  UploadSourceRequest,
  UploadSourceResponse,
} from 'type/Source'
import { SeriesRequest, SeriesResponse, TotalsRequest, TotalsResponse } from 'type/Statistics'
import { InviteRequest, InviteResponse } from 'type/User'
import { Data } from 'util/api'

class ApiController {
  async getWidget(company_id: string, saas?: boolean): Promise<DemoWidget> {
    return await this.get('/api/widget', { company_id, saas })
  }

  async getDemo(slug: string): Promise<DemoResponse> {
    return await this.get('/api/demo', { slug })
  }

  async getDemoCatalog(slug: string): Promise<DemoCatalogResponse> {
    return this.get('/api/demo-catalog', { slug })
  }

  async getStandDemoCatalog(slug: string): Promise<StandCatalogResponse> {
    return this.get('/api/stand-catalog', { slug })
  }

  async getDoc(path: string): Promise<string> {
    return await this.text('/api/doc', { path })
  }

  async getTotals(request: TotalsRequest): Promise<TotalsResponse> {
    return await this.get('/api/stats/totals', request)
  }

  async getSeries(request: SeriesRequest): Promise<SeriesResponse> {
    return await this.get('/api/stats/series', request)
  }

  async getCompanies(): Promise<SaasCompany[]> {
    const user_id = me.user.user_id
    return await this.get('/api/companies', { user_id })
  }

  async createCompany(request: CreateCompanyRequest): Promise<SaasCompany> {
    return await this.form('/api/company/create', request)
  }

  async updateCompany(request: UpdateCompanyRequest): Promise<SaasCompany> {
    return await this.form('/api/company/update', request)
  }

  async deleteCompany(company_id: string) {
    const user_id = me.user.user_id
    return await this.post('/api/company/delete', { user_id, company_id })
  }

  async getItemGroups(company_id: string): Promise<ItemGroup[]> {
    const user_id = me.user.user_id
    return await this.get('/api/groups', { user_id, company_id })
  }

  async getGroupFilters(company_id: string): Promise<GroupFilterConfig[]> {
    const user_id = me.user.user_id
    return await this.get('/api/group/filters', { user_id, company_id })
  }

  async getGroupItems(request: GroupItemsRequest): Promise<GroupItemsResponse> {
    return await this.post('/api/group/items', request)
  }

  async createGroup(request: CreateItemGroupRequest): Promise<ItemGroup> {
    return await this.post('/api/group/create', request)
  }

  async updateGroup(request: UpdateItemGroupRequest): Promise<ItemGroup> {
    return await this.post('/api/group/update', request)
  }

  async deleteGroup(group_id: string): Promise<void> {
    const user_id = me.user.user_id
    await this.post('/api/group/delete', { user_id, group_id })
  }

  async uploadImage(file: File): Promise<string> {
    const user_id = me.user.user_id
    const urls = await this.form<string[]>('/api/config/image/upload', { user_id, images: file })
    const url = urls[0]
    if (!url) throw new Error('no url')
    return url
  }

  async getCompanyConfig(company_id: string): Promise<CompanyConfig> {
    const user_id = me.user.user_id
    return await this.get('/api/config/company', { user_id, company_id })
  }

  async setCompanyConfig(company_id: string, config: CompanyConfig): Promise<void> {
    const user_id = me.user.user_id
    return await this.post('/api/config/company', { user_id, company_id, config })
  }

  async getProductsConfig(company_id: string): Promise<ProductsConfig> {
    const user_id = me.user.user_id
    return await this.get('/api/config/products', { user_id, company_id })
  }

  async createProductConfig(product: Product): Promise<void> {
    const user_id = me.user.user_id
    return await this.post('/api/config/product/create', { user_id, product })
  }

  async updateProductConfig(product: Product): Promise<void> {
    const user_id = me.user.user_id
    return await this.post('/api/config/product/update', { user_id, product })
  }

  async deleteProductConfig(company_id: string, product_id: string): Promise<void> {
    const user_id = me.user.user_id
    return await this.post('/api/config/product/delete', { user_id, company_id, product_id })
  }

  async getApiKeys(company_id: string): Promise<ApiKey[]> {
    const user_id = me.user.user_id
    return await this.get('/api/keys', { user_id, company_id })
  }

  async createApiKey(request: CreateApiKeyRequest): Promise<CreateApiKeyResponse> {
    return await this.post('/api/key/create', request)
  }

  async updateApiKey(request: UpdateApiKeyRequest): Promise<UpdateApiKeyResponse> {
    return await this.post('/api/key/update', request)
  }

  async deleteApiKey(key: string): Promise<void> {
    const user_id = me.user.user_id
    return await this.post('/api/key/delete', { user_id, key })
  }

  async getMembers(company_id: string): Promise<Member[]> {
    const user_id = me.user.user_id
    return await this.get('/api/members', { user_id, company_id })
  }

  async updateMember(request: UpdateMemberRequest): Promise<[MemberUpdate]> {
    return await this.form('/api/member/update', request)
  }

  async updatePermissions(request: UpdateMemberPermissionsRequest): Promise<unknown> {
    return await this.post('/api/permissions/update', request)
  }

  async deleteMember(member_id: string) {
    const user_id = me.user.user_id
    await this.post('/api/member/delete', { user_id, member_id })
  }

  async getMyCollections(): Promise<Collection[]> {
    const user_id = me.user.user_id
    return await this.get('/api/collections', { user_id })
  }

  async getCompanyCollections(company_id: string): Promise<Collection[]> {
    const user_id = me.user.user_id
    return await this.get('/api/company/collections', { user_id, company_id })
  }

  async createCollection(request: CreateCollectionRequest): Promise<Collection> {
    return await this.post('/api/collection/create', request)
  }

  async updateCollection(request: UpdateCollectionRequest): Promise<Collection> {
    return await this.post('/api/collection/update', request)
  }

  async deleteCollection(collection_id: string) {
    const user_id = me.user.user_id
    await this.post('/api/collection/delete', { user_id, collection_id })
  }

  async uploadCollectionImage(collection_id: string, file: File): Promise<Image> {
    const user_id = me.user.user_id
    const request = { user_id, object_id: collection_id, object_type: 'collection', image: file }
    return await this.post('/api/image/upload', request)
  }

  async deleteCollectionImage(image_id: string): Promise<void> {
    const user_id = me.user.user_id
    const request = { user_id, image_id }
    await this.post('/api/image/delete', request)
  }

  async getItem(item_id: string): Promise<CollectionItem> {
    const user_id = me.user.user_id
    return await this.get('/api/item', { user_id, item_id })
  }

  async getCollectionItems(collection_id: string): Promise<CollectionItems> {
    const user_id = me.user.user_id
    return await this.get('/api/items', { user_id, collection_id })
  }

  async uploadItems(request: UploadItemsRequest): Promise<void> {
    await this.post('/api/items/upload', request)
  }

  async createItem(request: CreateItemRequest): Promise<Item> {
    return await this.post('/api/item/create', request)
  }

  async updateItem(request: UpdateItemRequest): Promise<Item> {
    return await this.post('/api/item/update', request)
  }

  async updateItemStatus(request: UpdateItemStatusRequest): Promise<Item> {
    return await this.post('/api/item/status/update', request)
  }

  async deleteItem(item_id: string): Promise<void> {
    const user_id = me.user.user_id
    await this.post('/api/item/delete', { user_id, item_id })
  }

  async testItem(identity_id: string, item_id: string): Promise<NewInference> {
    const request = {
      user_id: me.user.user_id,
      identity_id,
      item_id,
      extension: 'simple',
    }
    return await this.post('/api/item/test', request)
  }

  async uploadItemImage(item_id: string, file: File): Promise<Image> {
    const user_id = me.user.user_id
    const request = { user_id, object_id: item_id, object_type: 'item', image: file }
    return await this.post('/api/image/upload', request)
  }

  async deleteItemImage(image_id: string): Promise<void> {
    const user_id = me.user.user_id
    const request = { user_id, image_id }
    await this.post('/api/image/delete', request)
  }

  async getSourceImages(item_id: string): Promise<Source[]> {
    const user_id = me.user.user_id
    const request = { user_id, item_id }
    return await this.get('/api/sources', request)
  }

  async uploadSourceImage(request: UploadSourceRequest): Promise<UploadSourceResponse> {
    return await this.post('/api/source/upload', request)
  }

  async updateSourceImage(request: UpdateSourceRequest): Promise<unknown> {
    return await this.post('/api/source/update', request)
  }

  async generateSourcePrompt(request: PromptSourceRequest): Promise<PromptSourceResponse> {
    return await this.post('/api/source/prompt', request)
  }

  async calculateSourceMasks(request: MasksSourceRequest): Promise<MasksSourceResponse> {
    return await this.post('/api/source/masks', request)
  }

  async deleteSourceImage(image_id: string, source_image_id: string): Promise<void> {
    const user_id = me.user.user_id
    const request = { user_id, image_id, source_image_id }
    return await this.post('/api/source/delete', request)
  }

  async getIdentities(): Promise<Identity[]> {
    const user_id = me.user.user_id
    return await this.get('/api/identities', { user_id })
  }

  async getInferences(): Promise<Inference[]> {
    const user_id = me.user.user_id
    return await this.get('/api/inferences', { user_id })
  }

  async getInference(inference_id: string): Promise<Inference> {
    const request = { inference_id }
    return await this.get('/api/inference', request)
  }

  async setInferenceImageLikeMode(image_id: number, like_mode: LikeState): Promise<void> {
    const request = { image_id, like_mode }
    await this.post('/api/inference/image/like', request)
  }

  async getComments(collection_id: string, item_id: string): Promise<CommentsResponse> {
    const user_id = me.user.user_id
    const request = { user_id, collection_id, item_id, type: 'public' }
    return await this.get('/api/comments', request)
  }

  async createComment(request: CreateCommentRequest): Promise<Comment> {
    return await this.post('/api/comment/create', request)
  }

  async createStatusComment(request: CreateStatusCommentRequest): Promise<Comment> {
    return await this.post('/api/comment/create', request)
  }

  async createLikeComment(request: CreateLikeCommentRequest): Promise<Comment> {
    return await this.post('/api/comment/create', request)
  }

  async updateComment(request: UpdateCommentRequest): Promise<Comment> {
    return await this.post('/api/comment/update', request)
  }

  async deleteComment(comment_id: string): Promise<void> {
    const user_id = me.user.user_id
    await this.post('/api/comment/delete', { user_id, comment_id })
  }

  async invite(request: InviteRequest): Promise<InviteResponse> {
    return await this.post('/api/invite', request)
  }

  async getSearchConfig(company_id: string): Promise<SearchConfigResponse> {
    const user_id = me.user.user_id
    return await this.get('/api/search/config', { user_id, company_id })
  }

  async search(request: SearchRequest): Promise<SearchResponse> {
    return await this.post('/api/search', request)
  }

  private async get<T>(path: string, request: Data): Promise<T> {
    try {
      return await rest.get(path, request)
    } catch (e) {
      notice.error()
      throw e
    }
  }

  private async post<T>(path: string, request: Data): Promise<T> {
    try {
      return await rest.post(path, request)
    } catch (e) {
      notice.error()
      throw e
    }
  }

  private async form<T>(path: string, request: Data): Promise<T> {
    try {
      return await rest.form(path, request)
    } catch (e) {
      notice.error()
      throw e
    }
  }

  private async text(path: string, request: Data): Promise<string> {
    try {
      return await rest.text(path, request)
    } catch (e) {
      notice.error()
      throw e
    }
  }
}

export const api = new ApiController()
