import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { ProductCard } from 'saas/page/products/components/ProductCard'
import { ProductSaasStore } from 'saas/store/product/ProductSaasStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'

function renderProductCard(product: ProductSaasStore): ReactElement | undefined {
  return <ProductCard key={product.product_id} product={product} />
}

export const ProductsList = observer(() => {
  const { products } = productsSaas.it

  return <Space gap={16} top wrap>
    {products.map(renderProductCard)}
  </Space>
})
