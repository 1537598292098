import { Input } from 'component/Input'
import { SearchSelect } from 'component/Select'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { filterConfigs } from 'saas/store/groups/GroupFilterConfigsCache'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { getFilterValueLabel } from 'type/GroupFilterConfig'

type FilterRowProps = {
  filter: GroupFilterStore
}

export const ValueInput = observer(({ filter }: FilterRowProps) => {
  const { value, error, onChange, onFocus } = filter.value
  const config = filterConfigs.it.config(filter.type.value)
  const values = filterConfigs.it.values(filter.type.value)
  const select = config?.type === 'list'

  if (select) {
    const options = values.map(val => {
      const value = val.val
      const label = getFilterValueLabel(val, config?.name)
      return { value, label }
    })
    return <Space width={320}>
      <SearchSelect wide value={value} error={error} options={options} onChange={onChange} />
    </Space>
  }

  return <Space width={320}>
    <Input wide value={value} error={error} onChange={onChange} onFocus={onFocus} />
  </Space>
})
