import { clsx } from 'clsx'
import { Button } from 'component/Button'
import { CopyButton } from 'component/Button/CopyButton'
import { IconButton } from 'component/Button/IconButton'
import { Link } from 'component/Link'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { try_on } from 'feature/widget/TryOnStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { ProductImage } from 'page/internal/products/ProductImage'
import { TagsInput } from 'page/internal/products/tags/TagsInput'
import { TagsList } from 'page/internal/products/tags/TagsList'
import { ExternalStatusSelect } from 'page/internal/status/ExternalStatusSelect'
import { InternalStatusSelect } from 'page/internal/status/InternalStatusSelect'
import { can } from 'store/can'
import { cfg } from 'store/cfg'
import { commenting } from 'store/comment/CommentingStore'
import { home } from 'store/home'
import { ProductItem } from 'store/product/ProductItem'
import { compact } from 'util/array'
import { formatDateSec, parseDate } from 'util/date'
import { emdash } from 'util/typo'
import css from './ProductInfoSection.module.scss'

const LIKE_ENABLED = false

type Props = {
  className?: string
  item: ProductItem
  num?: number
}

export const ProductInfoSection = observer(({ className, item, num }: Props) => {
  const collection = home.collection.optional
  const product = item.store.it
  const {
    company_id, collection_id, item_id, links, product_name,
    brand_name, article, external_item_id, updated_at,
  } = product.data.json
  const { collectionName } = product.data
  const { status, review } = product.form
  const { like, likes, dislikes } = product.comments
  const active = commenting.active(product)
  const updated = parseDate(updated_at)
  const { count } = product.comments
  const canPublish = status.value === 'done' && review.value !== 'public'

  const canAdd = can.AddAndEditProducts(company_id)
  const canDelete = can.DeleteProducts(company_id)
  const more = canAdd || canDelete

  const actions: DropdownItem[] = compact([
    canAdd && {
      key: 'open',
      label: i18n('item.Open'),
      async action() {
        await home.openProduct(product)
      },
    },
    canDelete && {
      key: 'delete',
      label: i18n('item.DeleteItem'),
      async action() {
        if (modal.openDeleteProductModal(item)) return
        await home.deleteProduct(item)
      },
    }])

  const onEdit = useLatestCallback(async () => {
    const { mosaica } = cfg.cfg
    if (!mosaica) return
    const url = new URL(mosaica)
    url.searchParams.append('coll_id', collection_id)
    url.searchParams.append('item_id', item_id)
    window.open(url, '_blank')
  })

  const onOpenComments = useLatestCallback(async () => {
    if (commenting.detached === product) commenting.close()
    else void commenting.open(product)
  })

  const onOpenWidget = useLatestCallback(async () => {
    await try_on.open(company_id, item_id, { saas: true })
  })

  const onPublic = useLatestCallback(async () => {
    review.onChange('public')
    await product.saveStatuses()
  })

  const onLike = useLatestCallback(async () => {
    await product.setLike(like === 1 ? 0 : 1)
  })

  const onDislike = useLatestCallback(async () => {
    await product.setLike(like === 2 ? 0 : 2)
  })

  return <Vertical className={clsx(className, css.section)} gap={12} tall>
    <Space gap={12} spread>
      <ProductImage className={css.image} item={item} selected={false} />
      <ProductImage className={css.image} item={item} selected={true} />
    </Space>

    <Space gap={[8, 12]} spread wrap>
      <InternalStatusSelect className={css.select} product={product} />
      <ExternalStatusSelect className={css.select} product={product} />
    </Space>

    <Vertical gap={8} tall>
      {collectionName && <Link text path={`/collection/${collection_id}`}>
        <Space opposite top>
          <Typo size={14} bold wrap>
            {collectionName}
          </Typo>
          <Icon name="arrow_forward" size={16} />
        </Space>
      </Link>}

      <Space gap={8} wrap>
        <Typo size={12} secondary ellipsis>
          {i18n('label.ItemId')}
        </Typo>
        <Space className={css.text} gap={4}>
          <Typo size={12} ellipsis>{item_id}</Typo>
          <CopyButton data={item_id} />
        </Space>
      </Space>

      <Space gap={8} wrap>
        <Typo size={12} secondary ellipsis>
          {i18n('label.Sku')}
        </Typo>
        <Space className={css.text} gap={4}>
          <Typo size={12} ellipsis tertiary={!external_item_id}>{external_item_id || emdash}</Typo>
          {external_item_id && <CopyButton data={external_item_id} />}
        </Space>
      </Space>

      <Space gap={8} top opposite>
        <Vertical gap={8}>
          <span>
            {num && <Typo className={css.num} size={16} bold secondary>{num}</Typo>}
            <Typo size={16} bold wrap>{product_name || emdash}</Typo>
          </span>
          <Typo size={14} wrap tertiary={!brand_name}>{brand_name || emdash}</Typo>
        </Vertical>

        {more && <Dropdown items={actions} placement="leftTop">
          <IconButton><Icon name="more_horiz" /></IconButton>
        </Dropdown>}
      </Space>

      <Gap spring margin={[-8, 0, 0, 0]} />

      <Space gap={8} wrap>
        <Typo size={12} secondary ellipsis>
          {i18n('label.Article')}
        </Typo>
        <Space className={css.text} gap={4}>
          <Typo size={12} ellipsis tertiary={!article}>{article || emdash}</Typo>
          {article && <CopyButton data={article} />}
        </Space>
      </Space>

      <Space gap={8} maxWidth={400}>
        <Icon name="link" size={14} secondary />
        <Space className={css.text} gap={4}>
          {links ? <Link href={links} small>
            {links}
          </Link> : <Typo size={12} tertiary>
            {emdash}
          </Typo>}
          {links && <CopyButton data={links} />}
        </Space>
      </Space>

      <Space gap={8} wrap>
        <Space gap={4} oh>
          <Icon size={14} name="calendar_today" secondary />
          <Typo size={12} secondary ellipsis>
            {i18n('label.LastUpdate')}
          </Typo>
        </Space>
        <Typo size={12}>{formatDateSec(updated) ?? emdash}</Typo>
      </Space>
    </Vertical>

    {collection ? <TagsInput product={product} /> : <TagsList product={product} />}

    <Space gap={[8, 8]} opposite wrap>
      <Space gap={[4, 8]} opposite wrap flex="1 1 auto">
        <Space gap={[0, 4]} wrap>
          {canAdd && <Button link compact action={onEdit}>
            {i18n('item.Edit')}
          </Button>}
          <Button link compact action={onOpenComments} active={active}>
            {i18n('item.Comments')} ({count})
          </Button>
        </Space>
        <Space gap={8} wrap>
          <Button action={onOpenWidget}>
            {i18n('item.TryOn')}
          </Button>
          <Button primary disabled={!canPublish} action={onPublic}>
            {i18n('item.Publish')}
          </Button>
        </Space>
      </Space>
      {LIKE_ENABLED && <Space wrap>
        <IconButton action={onLike} secondary primary={like === 1}>
          <Icon name="thumb_up" size={16} />
        </IconButton>
        {likes > 0 && <Typo size={12} primary>
          {likes}
        </Typo>}
        <IconButton action={onDislike} secondary error={like === 2}>
          <Icon name="thumb_down" size={16} />
        </IconButton>
        {dislikes > 0 && <Typo size={12} error>
          {dislikes}
        </Typo>}
      </Space>}
    </Space>
  </Vertical>
})
