import { production } from 'app/config/constants'
import { onceAsync, skipAsync } from 'util/async'
import { delay } from 'util/promise'

class TryOnPreviewStore {
  readonly preview = skipAsync(async (options: object, container: HTMLElement, item?: string) => {
    await this.create()
    window.showoff!.setup!({ ...options, internal: { container } })
    window.showoff!.open!({ item })
  })

  private create = onceAsync(async () => {
    const host = production ? 'try-on.showoff.app' : 'try-on-stage.show-off.org'
    const element = document.createElement('script')
    element.src = `https://${host}/widget.js`
    element.async = true
    document.head.appendChild(element)
    while (!window.showoff?.setup) {
      await delay(100)
    }
  })
}

export const try_on_preview = new TryOnPreviewStore()
