import { Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { filterConfigs } from 'saas/store/groups/GroupFilterConfigsCache'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { getFilterTypeLabel } from 'type/ItemGroupFilter'

type FilterRowProps = {
  filter: GroupFilterStore
}

export const TypeSelect = observer(({ filter }: FilterRowProps) => {
  const { value, error } = filter.type
  const values = filterConfigs.it.names
  const options = values.map(value => ({ value, label: getFilterTypeLabel(value) }))

  const onChange = useLatestCallback((value: string | null) => {
    filter.type.onChange(value || '')
    filter.action.reset('')
    filter.value.reset('')
  })

  return <Space width={200}>
    <Select wide value={value} error={error} options={options} onChange={onChange} />
  </Space>
})
