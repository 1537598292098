import { Image } from 'antd'
import { PreviewControl } from 'component/Image/PreviewControl'
import { useBodyScrollLock } from 'hook/useBodyScrollLock'
import { useLatestCallback } from 'hook/useLatestCallback'
import { PreviewGroupPreview } from 'rc-image/lib/PreviewGroup'
import { ReactNode, useMemo } from 'react'
import { mutable } from 'util/type'
import css from './Preview.module.scss'

type Props = {
  control: PreviewControl
  sources: readonly string[]
  strong?: boolean
  toolbar?: (source: string) => ReactNode
}

function getPreviewDefaults(): PreviewGroupPreview {
  return {
    rootClassName: css.preview,
    destroyOnClose: true,
    maxScale: 10,
    minScale: 0.1,
    scaleStep: 0.1,
  }
}

const container = document.createElement('div')
document.body.appendChild(container)

export const Preview = (props: Props) => {
  const { control, sources, strong, toolbar } = props
  const source = control.preview

  const onChange = useLatestCallback((index: number) => {
    control.setPreview(sources[index])
  })

  const onVisibleChange = useLatestCallback((open: boolean, _prev: boolean, index: number) => {
    control.setPreview(open ? sources[index] : undefined)
  })

  const openIndex = useMemo(() => {
    if (source == null) return undefined
    const found = sources.indexOf(source)
    return found < 0 ? 0 : found
  }, [source, sources])

  const visible = openIndex != null

  const preview: PreviewGroupPreview = {
    ...getPreviewDefaults(),
    current: openIndex,
    onChange,
    onVisibleChange,
    visible,
    countRender: sources.length > 1 ? undefined : () => null,
    maskClosable: !strong,
    toolbarRender: () => source && toolbar ? <div className={css.toolbar}>{toolbar(source)}</div> : null,
    getContainer: container,
  }

  useBodyScrollLock(visible)

  return <Image.PreviewGroup preview={preview} items={mutable(sources)} />
}
